
import { GrupaKrokow } from "./GrupaKrokow";
import { Supervisor } from "./Supervisor";
import { Slup } from "./Slup";
import { Control } from "./Control";

export class Pergola {

    szerokosc = 4000;
    glebokosc = 5000;
    szerokosc_slupa = 150;
    glebokosc_slupa = 85;

    minimalna_odleglosc = 800;

    szerokosc_w_px = 400;

    slupy:Slup[] = [];

    edytuj = -1;
    

    random = Math.ceil(Math.random() * 9999999999);

    scianaA = false;
    scianaB = false;
    scianaC = false;
    scianaD = false;
    IloscSlupow = 0;
    ListaStopy = [];
    WysokoscSlup = [];

    constructor(){
        
        this.JakiWymiar();
    }
    Init(control: Control){
        
            // console.log("pergola", this.control);
            control.Parameters.forEach(param => {
              if(param.Key == 'szerokosc'){
                if(!Number.isNaN(Number.parseInt(param.Value))){
                  this.szerokosc = Number.parseInt(param.Value) * 1;
                }
                
              } else if(param.Key == 'glebokosc'){
                if(!Number.isNaN(Number.parseInt(param.Value))){
                  this.glebokosc = Number.parseInt(param.Value) * 1;
                }
                
              } else if(param.Key == 'szerkosc_slupa'){
                if(!Number.isNaN(Number.parseInt(param.Value))){
                  this.szerokosc_slupa = Number.parseInt(param.Value) * 1;
                }
                
              } else if(param.Key == 'glebokosc_slupa'){
                if(!Number.isNaN(Number.parseInt(param.Value))){
                  this.glebokosc_slupa = Number.parseInt(param.Value) * 1;
                }
                
              } else if(param.Key == 'minimalna_odleglosc'){
                if(!Number.isNaN(Number.parseInt(param.Value))){
                  this.minimalna_odleglosc = Number.parseInt(param.Value) * 1;
                }
                
              } 
            });
            console.log("OP", control.OtherParams);
            control.OtherParams.forEach(param => {
              if(param.Key == 'ScianaA'){
                if(param.Value == "TAK"){
                  this.scianaA = true;
                } else {
                  this.scianaA = false;
                }
              } else if(param.Key == 'ScianaB'){
                if(param.Value == "TAK"){
                  this.scianaB = true;
                } else {
                  this.scianaB = false;
                }
              } else if(param.Key == 'ScianaC'){
                if(param.Value == "TAK"){
                  this.scianaC = true;
                } else {
                  this.scianaC = false;
                }
              } else if(param.Key == 'ScianaD'){
                if(param.Value == "TAK"){
                  this.scianaD = true;
                } else {
                  this.scianaD = false;
                }
              } else if(param.Key == 'IloscSlupow'){
                this.IloscSlupow = Number.parseInt(param.Value) * 1;
              } else if(param.Key == 'WysokoscSlup'){
                this.WysokoscSlup = param.Value.split("|");
              } else if(param.Key == 'ListaStopy'){
                this.ListaStopy = param.Value.split("|");
              } 
            });
            var skala = this.szerokosc_w_px / this.szerokosc;
            this.slupy.push(new Slup(0, 0, skala, this.szerokosc_slupa, this.glebokosc_slupa, this));
            
            this.slupy.push(new Slup(this.szerokosc - this.szerokosc_slupa, 0, skala, this.szerokosc_slupa, this.glebokosc_slupa, this));
            this.slupy.push(new Slup(0, this.glebokosc - this.glebokosc_slupa, skala, this.szerokosc_slupa, this.glebokosc_slupa, this));
            this.slupy.push(new Slup( this.szerokosc - this.szerokosc_slupa, this.glebokosc - this.glebokosc_slupa, skala, this.szerokosc_slupa, this.glebokosc_slupa, this));
            this.JakiWymiar();
          
    }
    dodajSlup(){
        var skala = this.szerokosc_w_px / this.szerokosc;
        var x = 200;
        this.slupy.push(new Slup(x, 0, skala, this.szerokosc_slupa, this.glebokosc_slupa, this));
        this.JakiWymiar();
    }
    dodajUchwyt(){
        
    }
    JakiWymiar() {
        this.slupy.forEach(s => {
            s.JakiWymiar(this);
        });
        
      }
    Edytuj(i){
        this.edytuj = i;
    }
    usunSlup(id){
        this.edytuj = -1;
        this.slupy.splice(id, 1);
    }
    poprawSlupy(){
        this.slupy.forEach(s=> {
            if(s.pozx < 0){
                s.pozx = 0;
            }
            if(s.pozy < 0){
                s.pozy = 0;
            }
            if(s.pozx > this.szerokosc - this.szerokosc_slupa){
                s.pozx = this.szerokosc - this.szerokosc_slupa;
            }
            if(s.pozy > this.glebokosc - this.glebokosc_slupa){
                s.pozy = this.glebokosc - this.glebokosc_slupa;
            }

            if(s.pozx> 0 && s.pozy > 0){
                if(s.pozx ==  this.szerokosc - this.szerokosc_slupa){

                } else if (s.pozy == this.glebokosc - this.glebokosc_slupa){

                } else {

                }
            }

        });
    }
    getWidth(){
        return this.szerokosc_w_px;
    }

    getHeight(){
        return  this.szerokosc_w_px / this.szerokosc * this.glebokosc;
    }
    getColumnWidth(){
        return  this.szerokosc_w_px / this.szerokosc * this.szerokosc_slupa;
    }
    getColumnHeight(){
        return  this.szerokosc_w_px / this.szerokosc * this.glebokosc_slupa;
    }

    getInnerWidth(){
        return  this.szerokosc_w_px / this.szerokosc * (this.szerokosc - 2*this.glebokosc_slupa);
    }
    getInnerHeight(){
        return  this.szerokosc_w_px / this.szerokosc * (this.glebokosc - 2*this.glebokosc_slupa);
    }
    getLeft(){
        return this.szerokosc_w_px / this.szerokosc * this.glebokosc /2 - 50;
    }
}