<!--The content below is only a placeholder and can be replaced.-->
<ng-container *ngIf="TranslationLoaded">

  <div class="strona_all podstrona_{{tresci.system}}" (click)="tresci.poza();">

    <div class="d-flex w-100 mx-auto flex-column min-vh-100">
      <app-menu *ngIf="IsLogedIn"></app-menu>
      <router-outlet [ngStyle]="{'margin-top': IsLogedIn? '110px': '50px'}"></router-outlet>
      <app-footer *ngIf="ShowFooter" class="mt-auto" style="padding-top: 30px;"></app-footer>
    </div>


    <p  *ngIf="IsLogedIn" class="pr-3 pl-3 app-version">v. {{Version}}</p>

    <div class="exception2 exception_typ_{{exception.typ}} exception_{{exception.animation_reset}}"
      *ngIf="exception.jest_except">
      <div class="pole_exception" (click)="exception.jest_except = false">
        <div *ngIf="exception.exception_successful_message !== pusty" id="{{exception.exception_successful_message}}"
          class="exception_ok" [translation]="TranslationEnum.Translation">
          {{ exception.exception_successful_message|translate }}
        </div>
        <div *ngIf="exception.exception_message !== pusty" id="{{exception.exception_message}}" class="exception_nie"
          [translation]="TranslationEnum.Translation">
          {{ exception.exception_message|translate }}
        </div>
        <div class="pole_dodane" *ngIf="exception.dodane != null" [innerHTML]="exception.dodane | safe: 'html'"></div>
        <div class="zamknij">X</div>
      </div>
    </div>

    <div class="exception" *ngIf="exception.jest_except">
      <div class="ogonek"></div>
      <div class="iks" (click)="exception.jest_except = false;">X</div>
      <div *ngIf="exception.exception_successful_message !== pusty" id="{{exception.exception_successful_message}}"
        class="exception_ok" [translation]="TranslationEnum.Translation">
        {{ exception.exception_successful_message|translate}}
      </div>
      <div *ngIf="exception.exception_message !== pusty" id="{{exception.exception_message}}" class="exception_nie"
        [translation]="TranslationEnum.Translation">
        {{ exception.exception_message|translate }}
      </div>
      <div class="historia_exc">
        <div *ngFor="let info of exception.info.pozycja; let i = index;">
          <div class="info" *ngIf="exception.porownaj(info.czas)">
            <div class="info_czas">{{info.czas | date:'H:mm:ss'}}</div>
            <div id="{{info.tresc}}" class="info_tresc" [translation]="TranslationEnum.Translation">
              {{info.tresc|translate}}
            </div>
          </div>
          <div class="info2" *ngIf="!exception.porownaj(info.czas)">
            <div class="info_czas">{{info.czas | date:'yyyy-MM-dd H:mm:ss'}}</div>
            <div id="{{info.tresc}}" class="info_tresc" [translation]="TranslationEnum.Translation">
              {{info.tresc|translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bug mb-3" *ngIf="User?.IsTrader" (click)="tresci.exception.podglad = true">
    </div>
    <div class="podglad_bug" *ngIf="tresci.exception.podglad">
      <h1 class="zamknij_bug">Podgląd błędów i parametrów</h1>
      <div class="container bug_container">
        <div>
          <h2>Ostatnie parametry</h2>
          <div class="bledy">
            <table border="1">
              <tr *ngFor="let blad of exception.getMessages(1); let i = index"
                (click)="exception.pokaz_message(blad.opis, blad.val);">
                <td>{{blad.czas | date:'yyyy-MM-dd H:mm:ss'}}</td>
                <td>{{blad.opis | slice:0:100}}</td>
                <td>pokaż</td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <h2>Ostatnie błedy</h2>
          <div class="bledy">
            <table border="1">
              <tr *ngFor="let blad of exception.getMessages(0); let i = index"
                (click)="exception.pokaz_message(blad.opis, blad.val);">
                <td>{{blad.czas | date:'yyyy-MM-dd H:mm:ss'}}</td>
                <td>{{blad.opis | slice:0:100}}</td>
                <td>pokaż</td>
              </tr>
            </table>
          </div>

        </div>
        <div class="pokaz_bud" *ngIf="tresci.exception.pokaz_bug">
          <div class="container">
            <div class="opis">
              <h2>{{tresci.exception.opis_bug | slice:0:80}}</h2>
            </div>
            <div class="tresc">
              <textarea *ngIf="setJson().error">{{setJson().error.Message}}</textarea>
              <textarea *ngIf="!setJson().error">{{this.tresci.exception.tresc_bug}}</textarea>
            </div>
            <div class="zamknij_bug2" (click)="tresci.exception.pokaz_bug = false;">X</div>
          </div>
        </div>
      </div>
      <h1 class="zamknij_bug" (click)="tresci.exception.podglad = false">Zamknij</h1>
      <div class="pod_bug">
        <div class="container">
          <div *ngIf="tresci.zamowienie != null && tresci.zamowienie.czyWczytuje()">
            <div class="wczytywane_box" *ngFor="let wczyt of tresci.zamowienie.parametryWczytane; let i = index">
              <div class="wczytwane wczytane_{{wczyt.Ile}}">
                {{wczyt.Key}} {{wczyt.Module}}-{{wczyt.Position}} ({{wczyt.Ile}})
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="Cache Cache_{{tresci.CacheTech}}" (click)="tresci.zmienCache()"
        title="szybkie wczytywanie (bez przetwarzania technologii)"></div>
      <div class="Price" (click)="tresci.zamowienie.liczCeny()" title="Liczenie cen"></div>
      <div class="DevMode DevMode_{{tresci.DevMode}}" (click)="tresci.zmienDevMode()" title="Dev mode"></div>
      <div class="delete" (click)="clearLocalStorage();" title="Czyszczenie błędów i parametrów"></div>
      <div class="error401" (click)="cast401();" title="Wywłaj error 401">error 401</div>

    </div>
    <div class="alert_box" *ngIf="tresci.alert.visible">
      <div class="alert2">
        <div *ngIf="tresci.alert.alert_tresc != null && tresci.alert.alert_tresc != pusty"
          id="{{tresci.alert.alert_tresc}}" class="tresc" [translation]="TranslationEnum.Translation">
          {{tresci.alert.alert_tresc|translate}}
        </div>
        <div class="tresc2" *ngIf="tresci.alert.alert_tresc2 != null && tresci.alert.alert_tresc2 != pusty">
          {{tresci.alert.alert_tresc2}}
        </div>
        <div id="ok" class="button" [translation]="TranslationEnum.Translation" (click)="tresci.alert.zamknij();">
          {{'ok'|translate}}
        </div>
      </div>
    </div>
  </div>
</ng-container>
