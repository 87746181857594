
import { GrupaKrokow } from "./GrupaKrokow";
import { Pergola } from "./Pergola";
import { Supervisor } from "./Supervisor";

export class Slup {
    wymiar_do_boku: boolean;

    pozx = 20;
    pozy = 20;
    width = 150;
    height = 150;
    width_skala = 150;
    height_skala = 150;
    skala = 1;
    blad: boolean = false;
    blad_nachodzi: boolean = false;
    dragPosition = {x: 0, y: 0};
    strona = 1;
    wymiar_x = 0;
    wymiar_y = 0;


    przenosze = false;

    constructor(pozx:number, pozy:number, skala, width, height, pergola:Pergola,){
        this.pozx = pozx;
        this.pozy = pozy;
        this.skala = skala;
        this.width = width;
        this.height = height;
        this.width_skala = width * skala;
        this.height_skala = height * skala;
        this.dragPosition = {x: this.getX(), y: this.getY()};
        this.jakaStrona(pergola);
        this.ustaw_wym();
    }


    ustaw(pergola: Pergola, id) {
        console.log("ustaw", pergola.slupy[id].pozx , pergola.slupy[id].pozy);
        pergola.slupy[id].pozx = Math.ceil(pergola.slupy[id].pozx*1);
        pergola.slupy[id].pozy = Math.ceil(pergola.slupy[id].pozy*1);
        if(pergola.slupy[id].pozx < 0){
            pergola.slupy[id].pozx = 0;
          }
          if(pergola.slupy[id].pozy < 0){
            pergola.slupy[id].pozy = 0;
          }
          if((pergola.slupy[id].pozx == 0 || pergola.slupy[id].pozx >  pergola.szerokosc - pergola.szerokosc_slupa) && pergola.slupy[id].pozy > pergola.glebokosc - pergola.szerokosc_slupa){
            pergola.slupy[id].pozy = pergola.glebokosc - pergola.szerokosc_slupa;
          } else if(pergola.slupy[id].pozy > pergola.glebokosc - pergola.glebokosc_slupa){
            pergola.slupy[id].pozy = pergola.glebokosc - pergola.glebokosc_slupa;
          }
          
          if(pergola.slupy[id].pozx > pergola.szerokosc - pergola.glebokosc_slupa){
            pergola.slupy[id].pozx = pergola.szerokosc - pergola.glebokosc_slupa;
          }
          if(pergola.slupy[id].pozx != 0 && pergola.slupy[id].pozx != pergola.szerokosc - pergola.glebokosc_slupa){
            if(pergola.slupy[id].pozy != 0 && pergola.slupy[id].pozy != pergola.glebokosc - pergola.glebokosc_slupa){
              //this.pergola.slupy[this.id].blad = true;
              var odleglosc_x1  = (pergola.szerokosc - pergola.glebokosc_slupa) - pergola.slupy[id].pozx;
              var odleglosc_x2  = pergola.slupy[id].pozx;
              var odleglosc_x = 0;
              if(odleglosc_x1 < odleglosc_x2){
                odleglosc_x =  odleglosc_x1;
              } else {
                odleglosc_x =  odleglosc_x2;
              }

             var odleglosc_y1  = (pergola.glebokosc - pergola.glebokosc_slupa) - pergola.slupy[id].pozy;
              var odleglosc_y2  = pergola.slupy[id].pozy;
              var odleglosc_y = 0;
              if(odleglosc_y1 < odleglosc_y2){
                odleglosc_y =  odleglosc_y1;
              } else {
                odleglosc_y =  odleglosc_y2;
              }

              if(odleglosc_x < odleglosc_y){
                if(odleglosc_x2 > odleglosc_x1){
                    pergola.slupy[id].pozx = pergola.szerokosc - pergola.glebokosc_slupa;  
                } else {
                    pergola.slupy[id].pozx = 0;
                }
                
              } else {
                if(odleglosc_y2 > odleglosc_y1){
                    pergola.slupy[id].pozy = pergola.glebokosc - pergola.glebokosc_slupa;  
                } else {
                    pergola.slupy[id].pozy = 0;
                }
              }
                 
              
            } else {
              //this.pergola.slupy[this.id].blad = false;
            }
          } else {
            //this.pergola.slupy[this.id].blad = false;
          }
          
        pergola.slupy[id].dragPosition = {x: pergola.slupy[id].getX(), y: pergola.slupy[id].getY()};
    }

    getX(){
        return this.pozx * this.skala;
    }
    getY(){
        return this.pozy * this.skala;
    }
    getWidth(){
        if(this.strona == 2 || this.strona == 4){
            return this.width * this.skala;
        } else {
            return this.height * this.skala;
        }
    }
    getHeight(){
        if(this.strona == 2 || this.strona == 4){
            return this.height * this.skala;
        } else {
            return this.width * this.skala;
        }
    }
    ustaw_wym(){
        if(this.strona == 2 || this.strona == 4){
            this.width_skala = this.width * this.skala;
            this.height_skala = this.height * this.skala;
        } else {
            this.width_skala =  this.height * this.skala;
            this.height_skala = this.width * this.skala;
        }
    }
    JakiWymiar(pergola:Pergola){
        //this.jakaStrona(pergola);
        this.wymiar_do_boku = true;
        this.wymiar_x = this.pozx;
        this.wymiar_y = this.pozy;
        
            pergola.slupy.forEach(s => {
                if(this.pozx == s.pozx && s.pozy < this.pozy){
                    if(this.wymiar_y > this.pozy - s.pozy - (s.getHeight() / this.skala)){
                        this.wymiar_y = this.pozy - s.pozy - (s.getHeight() / this.skala);
                        this.wymiar_do_boku =false;
                    }
                } 
            });

            pergola.slupy.forEach(s => {
                if((this.pozy == s.pozy || this.pozy == s.pozy + this.width - this.height || this.pozy == s.pozy - this.width + this.height) && s.pozx < this.pozx){
                    if(this.wymiar_x > this.pozx - s.pozx - (s.getWidth() / this.skala) || this.pozx - s.pozx - (s.getWidth() / this.skala) < pergola.minimalna_odleglosc){
                       
                            this.wymiar_x = this.pozx - s.pozx - (s.getWidth() / this.skala);
                            this.wymiar_do_boku =false;
                        
                    }
                } 
            });
            var czy_jest_taki_sam = false;
           
            for(var i = 0;i < pergola.slupy.length;i++){
                pergola.slupy[i].blad_nachodzi = false;
                for(var j = 0; j < pergola.slupy.length;j++){
                    if(i != j && pergola.slupy[i].pozy == pergola.slupy[j].pozy && pergola.slupy[i].pozx == pergola.slupy[j].pozx){
                        czy_jest_taki_sam = true;
                        pergola.slupy[i].blad_nachodzi = true;
                        pergola.slupy[j].blad_nachodzi = true;
                    } 
                }
            }


            var wymiar2 = pergola.glebokosc - this.pozy -(this.getHeight() / this.skala);
            var wymiar3 = pergola.szerokosc - this.pozx -(this.getWidth() / this.skala);
           /* if(czy_jest_taki_sam){
                this.blad = true;
            } else */
            
            if((this.strona == 1 || this.strona == 3) && wymiar2 < pergola.minimalna_odleglosc && wymiar2 != 0){
                
                this.blad = true;
            } else  if((this.strona == 2 || this.strona == 4) && wymiar3 < pergola.minimalna_odleglosc && wymiar3 != 0){
                
                this.blad = true;
            } else  if((this.wymiar_x < pergola.minimalna_odleglosc && this.wymiar_x != 0) || (this.wymiar_y < pergola.minimalna_odleglosc && this.wymiar_y != 0)){
                this.blad = true;
           } else {
                this.blad = false;
           }
           /*
            if(blad_tmp){
                this.blad = true;
            } else if((this.strona == 1 || this.strona == 3) && wymiar2 < pergola.minimalna_odleglosc && wymiar2 != 0){
                
                this.blad = true;
            } else  if((this.strona == 2 || this.strona == 4) && wymiar3 < pergola.minimalna_odleglosc && wymiar3 != 0){
                
                this.blad = true;
            } else  if(this.wymiar_do_boku && this.wymiar == 0){
                
                this.blad = false;
            } else  if( this.wymiar < pergola.minimalna_odleglosc){
                this.blad = true;
            } else {
                this.blad = false;
            }*/
            
    }
  
    jakaStrona(pergola:Pergola){
        var str = 4;
        if(this.pozx == 0){
            this.strona = 1;
        } else if (this.pozx == pergola.szerokosc - pergola.glebokosc_slupa){
            this.strona = 3;
        } else  if (this.pozy == 0){
            this.strona = 2;
        } else{
            this.strona = 4;
        }
        



        return this.strona;
    }
   
}