import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/shared/models/user/user.model';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { environment } from './../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { TresciService } from './tresci.service';
import { UserService } from './user.service';
import { ExceptionsMenagerService } from './exceptions-menager.service';
import { TranslationEnum } from './shared/enums/translation-enum';
import { VersionCheckService } from './shared/service/version-check.service';
import { take } from 'rxjs/operators';


declare function editTranslation(): void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit{

  public TranslationEnum = TranslationEnum;
  public TranslationLoaded: BehaviorSubject<boolean>;

  public podstrona = '';
  public pusty = '';
  public trescBug = '';
  public IsLogedIn = false;
  public User: User;
  public Version;

  public ShowFooter = true;

  constructor(public user: UserService, private translateService: TranslateService, public tresci: TresciService,
    public exception: ExceptionsMenagerService, public authorizationService: AuthorizationService, private cookieService: CookieService,
    versionCheckService: VersionCheckService, public router: Router) {
    versionCheckService.initVersionCheck(environment.versionCheckURL);

    tresci.wczytaj_tresci_tlumaczen2(window['culture'], 'stale');
    this.authorizationService.User.asObservable().subscribe((user: User) => {
      if (user) {
        if (user.IsFirst) {
          this.IsLogedIn = false;
        } else {
          this.IsLogedIn = true;
        }
        this.User = user;
        window['isTrader'] = user.IsTrader;
        this.translateService.langs = ['pl', 'en', 'de', 'fr', 'cs', 'it'];

      } else {
        this.IsLogedIn = false;
      }
    });
    this.Version = environment.appVersion;

    router.events.subscribe(() => {
      if (window.location.href.toLowerCase().includes('error')) {
        this.ShowFooter = false;
      }
    });

    this.loadLanguage();
    window['apiUrl'] = environment.apiUrl;
    window['site'] = 'stale';
    localStorage.site = "stale";

    this.TranslationLoaded = new BehaviorSubject(false);

    if (this.cookieService.check('lang')) {
      this.translateService.use(this.cookieService.get('lang')).subscribe(x => this.TranslationLoaded.next(true));
      window['culture'] = this.cookieService.get('lang');
    } else {
      let useLang = this.translateService.getBrowserLang();
      const langs = this.translateService.getLangs();
      if (!langs.includes(useLang)) {
        useLang = 'EN';
      }
      this.translateService.use(useLang).subscribe(x => this.TranslationLoaded.next(true)); 
      window['culture'] = useLang;
    }
  }
  cast401() {
    
    //localStorage.clear();
    //sessionStorage.clear();
    this.cookieService.delete('_token', '/');
    //this.cookieService.delete('_usi', '/');
    //this.cookieService.deleteAll('/');
    //this.authorizationService.User.next(null);
    
  }
  loadLanguage() {

    this.translateService.addLangs(['pl', 'en', 'de', 'fr', 'cs', 'it']);
    // if (this.User.IsTrader) {
    //   this.translateService.addLangs(['cs']);
    // }
  }

  public ngOnInit(): void {
    window['site'] = 'stale';
    localStorage.site = "stale";
    console.log("init", localStorage.changepassword);
    /*if(localStorage.changepassword == "change" && window.location.href.includes("Home")){
        localStorage.clear();
        sessionStorage.clear();
        this.cookieService.delete('_token', '/');
        this.cookieService.delete('_usi', '/');
        this.cookieService.deleteAll('/');
        this.authorizationService.User.next(null);
        this.router.navigate(['Login']);
    } else */
    if(localStorage.changepassword == "change" && !window.location.href.includes("Change-password")){
      console.log("loc", window.location.href);
      // this.router.navigate(['Change-password']);
      window.location.href ='Change-password';
    }
  }
 
  public clearLocalStorage() {
    localStorage.clear();
  }

  public setJson() {
    return JSON.parse(this.tresci.exception.tresc_bug);
  }



}
