import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { DataSharingService } from './../../shared/service/data-sharing.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { UniversalDialogComponent } from 'src/app/shared/components/universal-dialog/universal-dialog.component';
import { DialogData } from 'src/app/shared/models/dialog/dialog-data.model';
import { User } from 'src/app/shared/models/user/user.model';
import { ContractorChooseDialogComponent } from '../contractor-choose-dialog/contractor-choose-dialog.component';
import { KindOper } from 'src/app/shared/enums/kind-oper-enum';
import { PermissionEnum } from 'src/app/shared/enums/permission-enum';
import { CookieService } from 'ngx-cookie-service';
import { Contractor } from 'src/app/shared/models/user/contractor.model';
import { UserPermission } from 'src/app/shared/models/user/user-permission.model';
import { Subscription } from 'rxjs';
import { TresciService } from 'src/app/tresci.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  public TranslationEnum = TranslationEnum;
  public PermissionEnum = PermissionEnum;

  public ColorTechnology = 'accent';
  public ColorNotification = 'accent';
  public IsMenuCollapsed = true;
  public IsSystemsCollapsed = true;
  public User: User;
  public Contractor: Contractor;
  public Translate: UserPermission = null;
  public SystemsList = [];
  public ShowWarning = true;
  private subscriptions: Subscription[] = [];

  private showTechnologyPermission?: boolean;
  BlockingB2B: boolean;
  public IsTrader = false;

  constructor(private authorizationService: AuthorizationService, private router: Router, public dialog: MatDialog,
    private dataSharingService: DataSharingService, public translateService: TranslateService, private cookieService: CookieService, public tresci:TresciService) {

    this.User = new User(); 
    this.Contractor = new Contractor();

    authorizationService.User.subscribe(user => {
      if (user) {
        this.User = user;

        //// console.log("user", this.User);
        this.IsTrader = user.IsTrader;
        if (user.IsTrader) {
          this.SystemsList = ['ma', 'mad', 'pg', 'mp', 'mpd', 'fs', 'fsd', 'fr', 'frd', 'wgs', 'mv', 'mvd', 'sb'];
        } else if (user.ContractorSystems !== undefined) {
          user.ContractorSystems.forEach(system =>
            this.SystemsList.push(system.toLowerCase()));
        }
        /*
        const showTechnologyPermission = JSON.parse(sessionStorage.getItem('showTechnologyPermission'));
        if (showTechnologyPermission !== null) {
          this.showTechnologyPermission = !showTechnologyPermission;
          this.changeTechnologyShow();
        }
        */
       

        const contractor: Contractor = new Contractor();
        contractor.ContractorName = user.ContractorName;
        contractor.ContractorNumber = user.ContractorNumber;
        contractor.EnableEWdt = user.EnableEWdt;
        contractor.ConfirmationEWdt = user.ConfirmationEWdt;
        contractor.Country = user.Country;
        this.Contractor = contractor;

        if ((sessionStorage.getItem('_slc') !== undefined && sessionStorage.getItem('_slc') !== null) && this.User.IsTrader) {
          this.Contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
        } else {
          // console.log("con3", contractor);
          sessionStorage.setItem('_slc', JSON.stringify(contractor));
          this.Contractor = contractor;
        }
        
        this.BlockingB2B = this.User.BlockingB2B;
        this.Translate = this.authorizationService.hasPermission(PermissionEnum.Translation);
      }
    });


    this.ShowWarning = environment.isDevVersion;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }


  ngOnInit(): void {
  }

  public openContractorChooseDialog() {

    if (this.User.IsTrader) {
      if (window['site'] === 'stale' && localStorage.site === 'stale') {
        let dialogRef: MatDialogRef<ContractorChooseDialogComponent, any>;
        dialogRef = this.dialog.open(ContractorChooseDialogComponent, { minWidth: '1200px' });
        this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
          if (result != null) {
            this.Contractor = result;
            this.dataSharingService.contractorChanged.next(true);
          }
        }));
      } else {
        let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
        const data: DialogData = {
          title: '',
          content: 'nie_mozna_zmienic_kontrahenta_na_stronie_formularza',
          answerOk: true
        };
        dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
      }
    } else {
      this.routeTo('panel');
    }

    
  }
  public getColor() {
    if(this.tresci.DevMode){
      return 'primary';
    } else {
      return 'accent';
    }
  }
  public changeTechnologyShow() {
    this.tresci.zmienDevMode();
    
/*
    if (!this.showTechnologyPermission) {
      this.showTechnologyPermission = true;
      this.authorizationService.ShowTechnologyPermission.next(this.showTechnologyPermission);
      this.ColorTechnology = 'primary';

    } else {
      this.showTechnologyPermission = false;
      this.authorizationService.ShowTechnologyPermission.next(this.showTechnologyPermission);
      this.ColorTechnology = 'accent';
    }
    sessionStorage.setItem('showTechnologyPermission', JSON.stringify(this.showTechnologyPermission));*/
  }

  routeTo(site: string): void {
    this.router.navigate([`${site}`]);
  }

  routeToNewOrder(system: string): void {
    // this.router.navigate([`Order/${KindOper[KindOper.Add]}/${system.toUpperCase()}`]);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([`Order/${KindOper[KindOper.Add]}/${system.toUpperCase()}`]));
  }
  routeToNewOrder2(system: string): void {
    // this.router.navigate([`Order/${KindOper[KindOper.Add]}/${system.toUpperCase()}`]);
    // console.log(system);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([`NOrder/${KindOper[KindOper.Add]}/${system.toUpperCase()}`]));
  }

  public changeLanguage(lang: string) {
    const translate = this.authorizationService.hasPermission(PermissionEnum.Translation);
    let message = 'nie_mozna_zmienic_jezyka_na_stronie_formularza';
    if (translate?.Value === true) {
      message = 'nie_mozna_zmienic_jezyka_z_wlaczona_funkcja_tlumaczenia';
    }
    if ((window['site'] === 'stale' || localStorage.site === 'stale' ) && (translate === null || translate === undefined || translate?.Value === false)) {
      this.cookieService.set('lang', lang, { path: '/' });
      window['culture'] = lang;
      this.translateService.use(lang);
    } else {
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
      const data: DialogData = {
        title: '',
        content: message,
        answerOk: true
      };
      dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
    }

  }

  public logOut() {
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.delete('_token', '/');
    this.cookieService.delete('_usi', '/');
    this.cookieService.deleteAll('/');
    this.authorizationService.User.next(null);
    this.router.navigate(['Login']);

  }
}
