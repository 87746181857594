import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Control } from 'src/app/form-v2/form-model/Control';
import { Pergola } from 'src/app/form-v2/form-model/Pergola';
import { Slup } from 'src/app/form-v2/form-model/Slup';
import { DynamicFormComponent } from 'src/app/new-form/initial-form/dynamic-form/dynamic-form.component';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';

@Component({
  selector: 'app-edytuj-slup',
  templateUrl: './edytuj-slup.component.html',
  styleUrls: ['./edytuj-slup.component.scss']
})
export class EdytujSlupComponent implements OnInit {
  pergola: Pergola;
  public TranslationEnum = TranslationEnum;
  form:FormGroup;
  
  constructor(public dialogRef: MatDialogRef<DynamicFormComponent>, @Inject(MAT_DIALOG_DATA) public data:Pergola) {
    this.pergola = data;
    //this.pergola.edytuj
   }

  ngOnInit(): void {
    this.form = new FormGroup({
      state: new FormControl({value: "x", disabled: false}),
    });
  }
  sprawdz(slup:Slup) {
    slup.ustaw(this.pergola, this.pergola.edytuj);
    console.log(event);
    slup.ustaw_wym();
    this.pergola.JakiWymiar();
    
  }
  usunSlup(){
    this.pergola.usunSlup(this.pergola.edytuj);
    this.pergola.slupy.forEach(s => {
      s.ustaw_wym();
    });
    this.pergola.JakiWymiar();
    this.dialogRef.close();
  }
}
