import { FilterKim } from './shared/models/form/filter-kim';
import { OrderWarnings } from './shared/models/orders/order-warnings/order-warnings.model';
import { environment } from './../environments/environment';
import { alert } from './pomocnicze/alert';
import { Zamowienie } from './pomocnicze/zamowienie';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ExceptionsMenagerService } from './exceptions-menager.service';
import { UserService } from './user.service';
import { ActivatedRoute, Params } from '@angular/router';
import { deserialize, serialize } from 'class-transformer';
import { ListaZamowien } from './pomocnicze/listazamowien';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Proform } from './shared/models/order-register/proforms-list/proform.model';
import { ManagePositions } from './shared/models/form/manage-positions.model';
import { take, takeWhile } from 'rxjs/operators';

declare var $: any;

@Injectable({
  providedIn: 'root'
})


export class TresciService implements OnDestroy {
  // public server_url = 'http://192.168.1.201:8040';
  // public server_url = 'http://192.168.1.18:50016'; // Komp Sary
  // public server_url = 'http://t.api.b2b.selt.com'; // ovh



  //  public server_url = 'http://prod-api-b2b.selt.com';
  //  public czyWszystkiePozycje = false; //GDY WRZUCMY NA PROD

  // Nic tutaj już nie zmieniamy
  // Jeśli na deva chcemy wrzucić wpisujemy ng build
  // Jeśli na produkcyjny wpisujemy ng build --prod

  public server_url = environment.apiUrl;
  public czyWszystkiePozycje = environment.allPosition;
  private subscriptions: Subscription[] = [];


  public changePermission = false;
  public LastTechVersion = 0;
  public user: UserService = null;
  public jezyk = 'pol';
  public jezyk_id = '1';
  public jezyki = null;
  public system = null;
  public id = null;
  public komponent = 'Logowanie';
  public zmiana_contentu = 0;
  public tresci_json = null;
  public tresci_tlumaczen = [];

  public tresci_tlumaczen_zmienne:Tlumaczenie[] = [];
  
  public id_jez_tlumaczen = [];
  public zamowienie: Zamowienie = null;
  private id_tmp = 0;
  private id_generate = 0;
  public listazamowien = null;
  public Currency = null;
  public zamowienia: ListaZamowien = null;
  public archiwum = true;
  public archiwumLista: ListaZamowien = null;
  public wizualizacja = [];
  public wizualizacja_id = [];
  public id_krok_wizual = 0;
  public aktualne_id = -1;
  public zakladka = 0;
  public wydruk: SafeUrl = null;
  public zwijanie = 'mini';
  public foto_id = null;
  public foto_uwaga = false;
  public test_tresc = '';
  public system_wybrany = '';
  public alert: alert;
  public pokaz_wiz = false;
  public problem_wczytywanie = false;
  public CacheTech = true;
  public DevMode = false;
  public ProductVmList: any;
  public connectionData: boolean;
  public $IsPermission: BehaviorSubject<number>;
  public ManagePositions: ManagePositions;
  public $IsAfterPositions: BehaviorSubject<boolean>;
  IsTrader: any;
  ostatnieParametry: any;
  wczytywanie: boolean = false;





  // tslint:disable-next-line:max-line-length
  constructor(private router: Router, public http: HttpClient, private route: ActivatedRoute, private cookieService: CookieService,
    public exception: ExceptionsMenagerService, public sanitizer: DomSanitizer) {
    this.cookieService.set('zapis', 'ok');
    this.alert = new alert(this); 
    this.ManagePositions = new ManagePositions();

    this.$IsPermission = new BehaviorSubject<number>(3);
    this.$IsAfterPositions = new BehaviorSubject<boolean>(false);


    if (sessionStorage.getItem('jezyk') != null && sessionStorage.getItem('jezyk') !== '') {
      this.jezyk = sessionStorage.getItem('jezyk');
    }
    if (localStorage.getItem('Cache') === 'nie') {
      this.CacheTech = false;
    } else {
      this.CacheTech = true;
    }
    if (localStorage.getItem('DevMode') === 'tak') {
      this.DevMode = true;
    } else {
      this.DevMode = false;
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  public zmienDevModeOff() {
    this.DevMode = false;
    localStorage.setItem('DevMode', 'nie');
  }
  public zmienDevMode() {
    this.DevMode = !this.DevMode;
    if (this.DevMode) {
      localStorage.setItem('DevMode', 'tak');
    } else {
      localStorage.setItem('DevMode', 'nie');
    }
  }


  public zmienCache() {
    this.CacheTech = !this.CacheTech;
    if (this.CacheTech) {
      localStorage.setItem('Cache', 'tak');
    } else {
      localStorage.setItem('Cache', 'nie');
    }
  }
  public poWczytaniu() {
    this.getJezyki();
  }
  public getNewId() {
    this.id_tmp++;
    return this.id_tmp;
  }
  public newGenId() {
    this.id_generate++;
    return this.id_generate;
  }
  public scrollTo(klasa) {
    //$(klasa).html();
    // console.log("b", klasa);
    try {
      
      // console.log("1 raz", klasa);
      $('html, body').animate({
        scrollTop: $(klasa).offset().top
      }, 300);
    } catch (e) {
      // console.log("powtarza", klasa);
      setTimeout(() => {
        this.scrollTo(klasa);
      }, 300);
    }
  }
  public scrollToPozycja(klasa) {
    // $(klasa).html();
    // console.log("p", klasa);
    try {
      $('.pozycja_edit2').animate({
        scrollTop: $(klasa).offset().top + $('.pozycja_edit2').scrollTop()
      }, 300);
    } catch (e) {
      setTimeout(() => {
        this.scrollToPozycja(klasa);
      }, 300);
    }
  }

  public changePermissions(key: number) {
    this.$IsPermission.next(key);
    this.changePermission = true;
    switch (key) {
      case 1:
        this.user.IsPermission = true;
        this.user.IsTrader = true;
        break;
      case 2:
        this.user.IsPermission = false;
        this.user.IsTrader = true;
        break;
      case 3:
        this.user.IsPermission = false;
        this.user.IsTrader = false;
        break;
    }

    if (+sessionStorage.getItem('Premission') !== key) {
      if (this.zamowienie != null) {
        this.zamowienie.wyczysc_Parametry();
        window.location.reload();
      }
      sessionStorage.setItem('Premission', key.toString());
      key = +sessionStorage.getItem('Premission');

    }
  }


  public porownaj(a, b, asc = true) {
    if (a < b) { if (asc) { return -1; } else { return 1; } }
    if (a > b) { if (asc) { return 1; } else { return -1; } }
    return 0;
  }
  public setJezyk(jezyk) {
    this.jezyk = jezyk;
    if (this.jezyki != null) {
      this.jezyki.forEach(jez => {
        if (jez.Iso639.trim() === this.jezyk) {
          this.jezyk_id = jez.Id;
        }
      });
    }
    sessionStorage.setItem('jezyk', jezyk);

  }
  public setId(id) {
    this.id = id;
  }
  public setFunkcja(system) {
    this.system = system;
    sessionStorage.setItem('system', system);
    const komponent = this.router.url.split('/');
    if (komponent.length > 0) {
      this.komponent = komponent[1];
    }
    this.zmien_zapisz();
    // this.wczytaj_tresci();

  }
  public zmienJezyk(jezyk) {
    this.setJezyk(jezyk);
    // this.zmiana_contentu++;
    sessionStorage.setItem('jezyk', jezyk);
    this.zmienUrl();
    this.wczytaj_tresci();
    this.wczytaj_tresci_tlumaczen();
    this.zmien_zapisz();
  }
  public zmienFunkcje(system) {
    if (this.zamowienie != null) {
      this.zamowienie.wyczysc_Parametry();
    }
    this.system = system;
    // this.zmiana_contentu++;
    sessionStorage.setItem('system', system);
    this.zakladka = 0;
    this.zmienUrl();
    this.wczytaj_tresci();
    this.wczytaj_tresci_tlumaczen();
  }

  public zmienStrone(path, path2, id = null) {
    this.zapisz('aktualny_status_id', '');
    if (path !== 'X') {
      if (this.zamowienie != null) {
        this.zamowienie.wyczysc_Parametry();
      }

      this.system = path2;
      this.komponent = path;
      this.id = id;
      this.zakladka = 0;
      sessionStorage.setItem('system', path2);
      this.zmienUrl();
      if (this.zamowienie != null) {
        this.zamowienie = new Zamowienie(this);
        this.zamowienie.naglowek.wczytajKrok(0);
      }
      this.wczytaj_tresci();
      this.wczytaj_tresci_tlumaczen();
      this.zmien_zapisz();
    }
  }
  public zmienUrl() {
    if (this.system != null) {
      if (this.id != null) {
        this.router.navigate(['/' + this.komponent + '/' + this.jezyk + '/' + this.system + '/' + this.id]);
      } else {
        this.router.navigate(['/' + this.komponent + '/' + this.jezyk + '/' + this.system]);
      }

    } else {
      this.router.navigate(['/' + this.komponent + '/' + this.jezyk]);
    }
  }
  public zapisz(id, tresc, exp = 7 * 24 * 60) {
    if (exp <= 5) {
      // this.cookieService.set(id, tresc);
      sessionStorage.setItem(id, tresc);
    } else {
      // this.cookieService.set(id, tresc, new Date(new Date().getTime() + 1000 * 60 * exp));
      sessionStorage.setItem(id, tresc);
    }
  }
  public usun(id) {
    sessionStorage.setItem(id, '');
    // this.cookieService.delete(id);
  }
  public usun_cookies() {
    sessionStorage.clear();
    // this.cookieService.deleteAll();
  }
  public odczytaj(id) {
    return sessionStorage.getItem(id);
    // return this.cookieService.get(id);
  }
  public getJezyki() {
    const request = new HttpRequest('GET',
      '/api/WebContent/GetCultures',
      {},
      { headers: this.user.getHeader() });
    this.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            this.jezyki = event.body;
            // console.log(this.jezyki);
            this.jezyki.forEach(jez => {
              if (jez.Iso639.trim() === this.jezyk) {
                this.jezyk_id = jez.Id;
              }
            });
            this.wczytaj_tresci();
            this.wczytaj_tresci_tlumaczen();
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.exception.exception(this.exception.error(err), err);
        }
      });
  }

  // public async isConnectionToApi(): Promise<string> {
  //   const url =  this.server_url + '/api/WebContent/GetCultures';
  //   return (await this.http.get(url, { headers: this.user.getHeader(), observe: 'response' }).toPromise()).url;

  // }

  public isConnectionToApi() {
    const url = '/api/WebContent/GetCultures';
    this.http.get(url, { headers: this.user.getHeader(), observe: 'response' }).pipe(/*take(1)*/).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
          this.connectionData = true;
      }
    }, (err: any) => {
      this.connectionData = false;
    });
  }


  public get_plik(id) {

  }

  public dodaj_plik(Id: String, Dokument: string, Rozszerzenie: string) {

    const body = {
      'Id': Id,
      'File': Dokument,
      'FileExtension': Rozszerzenie
    };
    const dane = serialize(body);

    const request = new HttpRequest('POST',
      '/api/StorageFile/AddOrUpdateFile',
      dane,
      { headers: this.user.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
        }
      });
  }

  public usun_plik(Id: String) {

    const body = {
      'Id': Id
    };
    const dane = serialize(body);

    const request = new HttpRequest('POST',
      '/api/StorageFile/Remove',
      dane,
      { headers: this.user.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
        }
      });
  }

  public GetProducts() {

    const request = new HttpRequest('POST', '/api/ActiveForm/GetProducts', null, { headers: this.user.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
          this.ProductVmList = event.body;
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
      }
    });
  }

  public getPrefix() {
    let ProductPrefix = '';
    switch (this.system) {
      case 'Markiza':
        ProductPrefix = 'MA';
        break;
      case 'MarkizaDetale':
        ProductPrefix = 'MAD';
        break;
      case 'Veranda':
        ProductPrefix = 'MV';
        break;
      case 'VerandaDetale':
        ProductPrefix = 'MVD';
        break;
      case 'Pergola':
        ProductPrefix = 'PG';
        break;
      case 'Fasadowe':
        ProductPrefix = 'FS';
        break;
      case 'Refleksol':
        ProductPrefix = 'FR';
        break;
      case 'Sunbreaker':
        ProductPrefix = 'SB';
        break;
      case 'PergolaV':
        ProductPrefix = 'MP';
        break;
      case 'PergolaVDetale':
        ProductPrefix = 'MPD';
        break;
      case 'GlassSystem':
        ProductPrefix = 'WGS';
        break;
      case 'Handlowy':
        ProductPrefix = 'HA';
        break;
      case 'Test':
        ProductPrefix = 'TEST';
        break;
      case 'FasadoweDetale':
        ProductPrefix = 'FSD';
        break;
    }
    return ProductPrefix;
  }

  public wczytaj_tresci_tlumaczen2(culture, context, wymus = false) {
   // console.log("byl",this.tresci_tlumaczen_zmienne.findIndex(x => x.Context === context));
    if(this.tresci_tlumaczen_zmienne.findIndex(x => x.Context === context) < 0 || wymus){
      
        this.http.get( '/api/WebContent/GetWebContentI18n?site=stale&culture='+culture+'&webContentDate=2021-01-04T09:49:58').subscribe(result => {
         
            var x = new Tlumaczenie();
            x.Context = context;
            x.Values = result;
          
          this.tresci_tlumaczen_zmienne.push(x);
          // console.log("con", this.tresci_tlumaczen_zmienne);
        }, error => {
          this.exception.exception(this.exception.error(error), error);
        });

    } else {
      // console.log("con było", this.tresci_tlumaczen_zmienne);
    }
       
  }
  public wczytaj_tresci_tlumaczen() {
    // if (this.jezyki != null) {
    //   this.tresci_tlumaczen = [];
    //   this.id_jez_tlumaczen = [];
    //   this.jezyki.forEach(jez => {
    //     // tslint:disable-next-line:max-line-length
    //     this.http.get( '/api/WebContent/GetWebContent?site=' + this.getPrefix() + '&cultureId=' + jez.Id).subscribe(result => {
    //       this.tresci_tlumaczen.push(result as Dane[]);
    //       this.id_jez_tlumaczen.push(jez.Id);
    //     }, error => {
    //       this.exception.exception(this.exception.error(error), error);
    //     });

    //   });
    // }
  }

  public copyMessage(val: string, opis, typ) {
    this.exception.copyMessage(val, opis, typ);
    /*
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    */
  }
  public get_tresc(id, jezyk = null, edit = true) {
    if (jezyk == null) {
      jezyk = this.jezyk_id;
    }
    let tresc = '' + id + '';
    if (this.tresci_tlumaczen != null) {
      for (let index = 0; index < this.id_jez_tlumaczen.length; index++) {
        if (this.id_jez_tlumaczen[index].toString() === jezyk.toString()) {
          this.tresci_tlumaczen[index].forEach(tlumaczenie => {
            if (tlumaczenie.Key.toLowerCase() === id.toLowerCase() && tlumaczenie.Context === this.getPrefix()) {
              tresc = tlumaczenie.Value;
            }
          });
        }
      }
    }
    if (edit) {
      if (this.user.IsPermission) {
        // tslint:disable-next-line:max-line-length
        return '<span class="editable"><span contenteditable="true" class="tresc_editable tresc_zmienne_' + jezyk + '_' + this.getPrefix() + '_' + id + '" id="tresc_zmienne_' + jezyk + '_' + this.getPrefix() + '_' +
          id + '">' + tresc + '</span>' +
          // tslint:disable-next-line:max-line-length
          '<span class="zapisz_editable zapisz_editable_zmienne" id = "' + id + '" data-str = "' + this.getPrefix() + '" data-jezyk_id = "' + jezyk + '" >Zapisz<span class="zapisuje"></span></span></span>';
      } else {
        return '<span class="tresc_zmienne_' + jezyk + '_' + this.getPrefix() + '_' + id + '">' + tresc + '</span>';
      }
    } else {
      return tresc;
    }

  }

  public wczytaj_tresci() {
    // this.http.get('/api/WebContent/GetWebContent?site=' + this.system + '&cultureId=' + this.jezyk_id).subscribe(result => {
    //   this.tresci_json = result as Dane[];
    //   this.tresci_json.forEach(element => {
    //     element.Value = element.Value.replace(' i ', ' i&nbsp;');
    //     element.Value = element.Value.replace(' a ', ' a&nbsp;');
    //     element.Value = element.Value.replace(' o ', ' o&nbsp;');
    //     element.Value = element.Value.replace(' w ', ' w&nbsp;');
    //     element.Value = element.Value.replace(' z ', ' z&nbsp;');
    //     element.Value = element.Value.replace(' w ', ' w&nbsp;');
    //     if (element.SubPage === this.system) {
    //       //  $('.tresc_zmienne_' + this.system + '_' + element.Id ).html(element.Value);
    //       //  setTimeout(() => {$('.tresc_zmienne_' + this.system + '_' + element.Id ).html(element.Value); } , 1000);
    //     } else if (element.SubPage === 'stale') {
    //       // $('.tresc_stale_' + element.Id ).html(element.Value);
    //       // setTimeout(() => {$('.tresc_stale_' + element.Id ).html(element.Value); } , 1000);
    //     }
    //     this.zmiana_contentu++;
    //   });
    // }, error => { this.exception.exception(this.exception.error(error), error) });

  }
  public poza() {
    if (this.zamowienie != null) {
      this.zamowienie.poza();
    }
    this.user.poza();
  }
  public czy_pelne(id, typ, str_prefix = null) {
    id = this.bez_polskich(id);
    id = id.replace(/ /g, '_');
    id = id.replace(/\./g, '');
    let tresc: String = '';
    if (str_prefix == null) {
      str_prefix = this.system;
    }
    let Context = 'stale';
    if (typ === 'stale') {
      Context = 'stale';
    } else {
      Context = str_prefix;
    }
    if (this.tresci_json != null && (typ === 'stale' || typ === 'zmienne' || typ === 'bezpustych')) {
      this.tresci_json.forEach(element => {
        if (element.Key != null && id != null && element.Key.toLowerCase() === id.toLowerCase() && element.Context === Context) {
          tresc = element.Value;
        }
      });
    }
    if (tresc === '.' || tresc === '.<br>' || tresc === '<br>' || tresc === '.<br/>' || tresc === '') {
      return false;
    } else {
      return true;
    }
  }
  public tresc(id, typ, str_prefix = null) {
    id = this.bez_polskich(id);
    id = id.replace(/ /g, '_');
    id = id.replace(/\./g, '');
    let tresc: String = '';
    if (str_prefix == null) {
      str_prefix = this.system;
    }
    let Context = 'stale';
    if (typ === 'stale') {
      Context = 'stale';
    } else {
      Context = str_prefix;
    }
    if (this.tresci_json != null && (typ === 'stale' || typ === 'zmienne' || typ === 'bezpustych')) {
      this.tresci_json.forEach(element => {
        if (element.Key != null && id != null && element.Key.toLowerCase() === id.toLowerCase() && element.Context === Context) {
          tresc = element.Value;
        }
      });
    }
    if (typ === 'form') {
      const jezyk = this.jezyk_id;
      if (this.tresci_tlumaczen != null) {
        for (let index = 0; index < this.id_jez_tlumaczen.length; index++) {
          if (this.id_jez_tlumaczen[index].toString() === jezyk.toString()) {
            this.tresci_tlumaczen[index].forEach(tlumaczenie => {
              if (tlumaczenie.Key.toLowerCase() === id.toLowerCase() && tlumaczenie.Context === this.getPrefix()) {
                tresc = tlumaczenie.Value;
              }
            });
          }
        }
      }
    }

    tresc = tresc.replace(/font-size:/g, '-mmm3-old:');
    tresc = tresc.replace(/ i /g, ' i&nbsp;');
    tresc = tresc.replace(/ a /g, ' a&nbsp;');
    tresc = tresc.replace(/ o /g, ' o&nbsp;');
    tresc = tresc.replace(/ w /g, ' w&nbsp;');
    tresc = tresc.replace(/ z /g, ' z&nbsp;');
    if (tresc === '' && (typ !== 'bezpustych' || this.user.IsPermission)) {
      tresc = id;
    }

    return tresc;
  }
  public pole_zmienne(id, zmiana) {
    id = this.bez_polskich(id);
    id = id.replace(/ /g, '_');
    id = id.replace(/\./g, '');
    let tresc: String = '';

    if (this.tresci_json != null) {
      this.tresci_json.forEach(element => {
        if (element.Key != null && id != null && element.Key.toLowerCase() === id.toLowerCase() && element.Context === this.system) {
          tresc = element.Value;
        }
      });
    }

    tresc = tresc.replace(/font-size:/g, '-mmm3-old:');
    tresc = tresc.replace(/ i /g, ' i&nbsp;');
    tresc = tresc.replace(/ a /g, ' a&nbsp;');
    tresc = tresc.replace(/ o /g, ' o&nbsp;');
    tresc = tresc.replace(/ w /g, ' w&nbsp;');
    tresc = tresc.replace(/ z /g, ' z&nbsp;');
    if (tresc === '') {
      tresc = id;
    }

    if (this.user.IsPermission) {
      // tslint:disable-next-line:max-line-length
      return '<span class="editable"><span contenteditable="true" class="tresc_editable tresc_zmienne_' + this.jezyk_id + '_' + this.system + '_' + id + '" id="tresc_zmienne_' + this.jezyk_id + '_' + this.system + '_' +
        id + '">' + tresc + '</span>' +
        '<span class="zapisz_editable zapisz_editable_zmienne" id = "' + id + '" data-jezyk_id = "' + this.jezyk_id + '" >Zapisz<span class="zapisuje"></span></span></span>';
    } else {
      return '<span class="tresc_zmienne_' + this.jezyk_id + '_' + this.system + '_' + id + '">' + tresc + '</span>';
    }
  }
  public pole_stale(id, zmiana) {
    id = this.bez_polskich(id);
    if (id == null) {
      return '';
    } else {
      id = id.replace(/ /g, '_');
      id = id.replace(/\./g, '');
      let tresc: String = '';

      if (this.tresci_json != null) {
        this.tresci_json.forEach(element => {
          if (element.Key.toLowerCase() === id.toLowerCase() && element.Context === 'stale') {
            tresc = element.Value;
          }
        });
      }

      tresc = tresc.replace(/font-size:/g, '-mmm3-old:');
      tresc = tresc.replace(/ i /g, ' i&nbsp;');
      tresc = tresc.replace(/ a /g, ' a&nbsp;');
      tresc = tresc.replace(/ o /g, ' o&nbsp;');
      tresc = tresc.replace(/ w /g, ' w&nbsp;');
      tresc = tresc.replace(/ z /g, ' z&nbsp;');
      if (tresc === '') {
        tresc = id;
      }

      if (this.user.IsPermission) {
        // tslint:disable-next-line:max-line-length
        return '<span class="editable"><span contenteditable="true" class="tresc_editable tresc_stale_' + this.jezyk_id + '_' + id + '" id="tresc_stale_' + this.jezyk_id + '_' +
          id + '">' + tresc + '</span>' +
          '<span class="zapisz_editable zapisz_editable_stale" id = "' + id + '" data-jezyk_id = "' + this.jezyk_id + '" >Zapisz<span class="zapisuje"></span></span></span>';
        // return '<span class="tresc_stale_' + this.jezyk_id  + '_' + id + '">' + tresc + '</span>';
      } else {
        return '<span class="tresc_stale_' + this.jezyk_id + '_' + id + '">' + tresc + '</span>';
      }
    }

  }

  public pole_stale_odczyt(id, zmiana) {
    id = this.bez_polskich(id);
    id = id.replace(/ /g, '_');
    id = id.replace(/\./g, '');
    let tresc: String = '';

    if (this.tresci_json != null) {
      this.tresci_json.forEach(element => {
        if (element.Id === id && element.SubPage === 'stale') {
          tresc = element.Value;
        }
      });
    }

    tresc = tresc.replace(/font-size:/g, '-mmm3-old:');
    tresc = tresc.replace(/ i /g, ' i&nbsp;');
    tresc = tresc.replace(/ a /g, ' a&nbsp;');
    tresc = tresc.replace(/ o /g, ' o&nbsp;');
    tresc = tresc.replace(/ w /g, ' w&nbsp;');
    tresc = tresc.replace(/ z /g, ' z&nbsp;');
    if (tresc === '') {
      tresc = id;
    }
    return '<span class="tresc_stale_' + this.jezyk_id + '_' + id + '">' + tresc + '</span>';

  }

  public pole_stale_odczyt_bez(id, zmiana = null) {
    id = this.bez_polskich(id);
    id = id.replace(/ /g, '_');
    id = id.replace(/\./g, '');
    let tresc: String = '';

    if (this.tresci_json != null) {
      this.tresci_json.forEach(element => {
        if (element.Id === id && element.SubPage === 'stale') {
          tresc = element.Value;
        }
      });
    }

    tresc = tresc.replace(/font-size:/g, '-mmm3-old:');
    tresc = tresc.replace(/ i /g, ' i&nbsp;');
    tresc = tresc.replace(/ a /g, ' a&nbsp;');
    tresc = tresc.replace(/ o /g, ' o&nbsp;');
    tresc = tresc.replace(/ w /g, ' w&nbsp;');
    tresc = tresc.replace(/ z /g, ' z&nbsp;');
    if (tresc === '') {
      tresc = id;
    }
    return tresc;

  }

  public bez_polskich(Name) {
    /* Name = Name.replace('ń', 'n');
     Name = Name.replace('Ń', 'N');
     Name = Name.replace('ż', 'z');
     Name = Name.replace('Ż', 'Z');
     Name = Name.replace('ź', 'z');
     Name = Name.replace('Ź', 'Z');
     Name = Name.replace('Ć', 'C');
     Name = Name.replace('ć', 'c');
     Name = Name.replace('ę', 'e');
     Name = Name.replace('Ę', 'E');
     Name = Name.replace('Ś', 'S');
     Name = Name.replace('ś', 's');
     Name = Name.replace('Ą', 'A');
     Name = Name.replace('ą', 'a');*/
    return Name;
  }
  public zmieniono_poprawnie(id, tresc, Context, typ) {
    id = this.bez_polskich(id);
    if (id == null) {
      return '';
    } else {
      id = id.replace(/ /g, '_');
      id = id.replace(/\./g, '');


      if (typ === 'form') {
        const jezyk = this.jezyk_id;
        if (this.tresci_tlumaczen != null) {
          for (let index = 0; index < this.id_jez_tlumaczen.length; index++) {
            if (this.id_jez_tlumaczen[index].toString() === jezyk.toString()) {
              this.tresci_tlumaczen[index].forEach(tlumaczenie => {
                if (tlumaczenie.Key.toLowerCase() === id.toLowerCase() && tlumaczenie.Context === this.getPrefix()) {
                  tlumaczenie.Value = tresc;
                }
              });
            }
          }
        }
      } else {
        if (this.tresci_json != null) {
          this.tresci_json.forEach(element => {
            if (element.Key.toLowerCase() === id.toLowerCase() && element.Context === Context) {
              element.Value = tresc;

            }
          });
        }
      }
    }
  }
  public zapisz_pole(id, tresc, typ, str_prefix) {
    id = this.bez_polskich(id);
    id = id.replace(/ /g, '_');
    id = id.replace(/\./g, '');
    const jezyk_id = this.jezyk_id;
    if (str_prefix == null) {
      str_prefix = this.system;
    }
    let Context = 'stale';
    if (typ === 'stale') {
      Context = 'stale';
    } else if (typ === 'form') {
      Context = this.getPrefix();
    } else {
      Context = str_prefix;
    }
    const dane = {
      'Key': id,
      'Context': Context,
      'Value': tresc,
      'CultureId': jezyk_id
    };
    const body = serialize(dane);
    const request = new HttpRequest('POST', '/api/WebContent/AddWebContent', body,
      { headers: this.user.getHeaderJson(), reportProgress: true });
    this.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {

          case HttpEventType.Response:
            /*$(".tresc_"+id +" .zapisuje").hide();*/
            this.zmieniono_poprawnie(id, tresc, Context, typ);
            this.exception.exception_successful('zapisano poprawnie', 'zapisano poprawnie', 'mini', tresc);
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.exception.exception_successful('błąd zapisu', err);
        }
      });
  }

  public zmien_zapisz() {
    /*
        const http = this.http;
        const user = this.user;
        const system = this.system;
        const server_url = this.server_url;
        setTimeout(function() {

          $('.zapisz_editable_zmienne').click(function(e) {
            const id = $(this).attr('id');
            const jezyk_id = $(this).attr('data-jezyk_id');
            let str_prefix = $(this).attr('data-str');
            if (str_prefix == null) {
              str_prefix = system;
            }

            let tresc = $('#tresc_zmienne_' + jezyk_id + '_' + str_prefix + '_' + id ).html();

            const dane = {
              'Key' : id,
              'Context' : str_prefix,
              'Value' : tresc,
              'CultureId' : jezyk_id
            };
            const body = serialize(dane);
            const request = new HttpRequest('POST', server_url + '/api/WebContent/AddWebContent', body,
            {headers: user.getHeaderJson(), reportProgress: true });
            http.request(request)
              .subscribe((event: HttpEvent<any>) => {
                switch (event.type) {

                  case HttpEventType.Response:
                    // $(".tresc_"+id +" .zapisuje").hide();
                }
              }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                }
              });
          });


          $('.zapisz_editable_stale').click(function(e) {
            const id = $(this).attr('id');
            const jezyk_id = $(this).attr('data-jezyk_id');

            let tresc = $('#tresc_stale_' + jezyk_id + '_' + id ).html();

            const dane = {
              'Key' : id,
              'Context' : 'stale',
              'Value' : tresc,
              'CultureId' : jezyk_id
            }
            const body = serialize(dane);
            const request = new HttpRequest('POST', server_url + '/api/WebContent/AddWebContent', body,
            {headers: user.getHeaderJson(), reportProgress: true });
            http.request(request)
              .subscribe((event: HttpEvent<any>) => {
                switch (event.type) {

                  case HttpEventType.Response:
                    // $(".tresc_"+id +" .zapisuje").hide();
                }
              }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                }
              });
          });

        }, 3000);
        */
  }



  // ------------------------------------------------------------------------------ SB2B-331 ------------------------------------------

  public OrderVerification(ids) {
    return this.http.post<OrderWarnings[]>('/api/ActiveForm/OrderVerification?', ids,
      { headers: this.user.getHeaderJson() });
  }

  public SaveOrderVerificationForProforma(ids) {
    return this.http.post<OrderWarnings[]>('/api/ActiveForm/SaveOrderVerificationForProforma?', ids,
      { headers: this.user.getHeaderJson() });
  }

  cancelOrders(ids) {
    const request = new HttpRequest('POST',
      '/api/ActiveForm/CancelOrders?CultureId=' + this.jezyk_id,
      ids,
      { headers: this.user.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
        }
      }, (err: any) => {
        this.exception.exception(this.exception.error(err), err);
        this.exception.exception('Nie wysłano', err);
      });
  }

  removeOrders(ids) {
    const request = new HttpRequest('POST',
      '/api/ActiveForm/RemoveOrders?CultureId=' + this.jezyk_id,
      ids,
      { headers: this.user.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
        }
      }, (err: any) => {
        this.exception.exception(this.exception.error(err), err);
        this.exception.exception('Nie wysłano', err);
      });
  }

  checkingAvailability(ids: number[]): Observable<OrderWarnings[]> {
    return this.http.post<OrderWarnings[]>('/api/ActiveForm/CheckingAvailability?Culture=' + window['culture'], ids,
      { headers: this.user.getHeaderJson() });
  }

  getBulkPayments(dateFrom: string, dateTo: string) {
    return this.http.post<Proform[]>('/api/ActiveForm/GetOrderByProformaNumber?dateFrom=' + dateFrom
      + '&dateTo=' + dateTo, `{"ContractorNumber": ${this.user.ContractorNumber}}`,
      { headers: this.user.getHeaderJson() });
  }

  saveOrdersWithOutVeryficatfionInMultiPaymentWithProfroma(id: number) {
    return this.http.post<any>('/api/ActiveForm/SaveOrdersWithOutVeryficatfionInMultiPaymentWithProfroma?', id,
      { headers: this.user.getHeaderJson() }).pipe(/*take(1)*/).subscribe();
  }
  removeNrProformy(proforma: string) {
    return this.http.post<any>('/api/ActiveForm/RemoveNrProformy?', `{"ProformaFormat": "${proforma}"}`,
      { headers: this.user.getHeaderJson() });
  }

  getProformaPrintout(proforma) {
    return this.http.post('api/ActiveForm/GetProformaPrintout?culture=' + window['culture']
      , `{"ProformaFormat": "${proforma}"}`, { headers: this.user.getHeaderJson() }).pipe(/*take(1)*/).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
        }
      }, (err: any) => {
        this.exception.exception(this.exception.error(err), err);
        this.exception.exception('Nie wysłano', err);
      });
  }




  getSystemFilter() {
    return this.http.get<FilterKim[]>('/api/ActiveForm/SystemFilter?system=' + this.getPrefix(),
      { headers: this.user.getHeaderJson() });
  }

  // ------------------------------------------------------------------------------ SB2B-331 ------------------------------------------

}


interface Dane {
  Id: String;
  DateModfiy: String;
  SubPage: String;
  Value: String;
}
export class Tlumaczenie {
  Context: String;
  Values: any;
}
