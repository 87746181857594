

<ng-container *ngFor="let krok of GrupaKrokow.kroki; let i = index;">
    <div class="kroki aktywny{{GrupaKrokow.CzyAktywny(i)}} poprawne_{{Poprzednie}}" id = "krok_{{krok.Step}}"  >
        <ng-container *ngIf = "GrupaKrokow.CzyWidocznyStep(i, krok) && (krok.czyWidoczny() || tresci.DevMode)">
            <div *ngIf = "tresci.DevMode" class = "nazwa_krok">{{i}}. {{krok.Step}} ({{krok.Type}})</div>
            <div class = "controls">
                    <ng-container   *ngFor="let control of krok.Controlls; let j = index;">
                        
                        <ng-container *ngIf = "tresci.DevMode || !control.Hidden">
                            <div class="span{{JakaSzerokosc(control)}} kontrolka_pole">
                                <app-form-control [FormData] = "FormData" [control] = "control" [GrupaKrokow] = "GrupaKrokow" [i] = "i" [DevMode] = "tresci.DevMode" ></app-form-control>
                            </div>
                           
                        </ng-container>
                            <!--<div class="kontrolki poprawna{{control.poprawne}}">{{control.ControlType}} - {{control.KeyName}} - {{control.wybrany.DisplayValue}} (<span *ngFor="let v of control.Values; let kk = index;">{{v.Key}} - </span>)
                                <div class="pole_wartosc"><input name="parametr" (change) = "FormData.ZmianaParametru(control)" [disabled] = "!GrupaKrokow.CzyAktywny(i)" [(ngModel)]="control.wybrany.Value" /></div>

                            </div> -->
                            
                            
                    </ng-container>
            </div>
            <div class="next" *ngIf = "!FormData.wczytywanie">
                <button mat-flat-button color="primary" *ngIf = "GrupaKrokow.CzyAktywny(i)"  (click) = "FormData.next(tresci, formService, typ, true, dialog)" class="float-right" [id]="'btn_'+CurrentStep"
                type="submit">
                <span id="dalej" [translation]="TranslationEnum.TranslationForm">
                    {{'dalej'|translate}}
                </span>
                <mat-icon class="ml-1">forward</mat-icon>
                </button>
                <button mat-flat-button  *ngIf = "!GrupaKrokow.CzyAktywny(i)"  (click) = "FormData.active(GrupaKrokow, i, module, position, typ)" type="button"  color="primary" 
                class="float-right" [name]="CurrentStep" >
                <span id="edytuj" [translation]="TranslationEnum.TranslationForm">
                    {{'edytuj'|translate}}
                </span>
                <mat-icon class="ml-1">create</mat-icon>
                </button>
                
                <button mat-flat-button  class="akt_button weryfikuj"   *ngIf = "GrupaKrokow.CzyAktywny(i) && FormData.supervisor.skip" class="float-right Weryfikuj"
                (click) = "Weryfikuj()">
                <span id="weryfikuj" [translation]="TranslationEnum.TranslationForm">
                    {{'weryfikuj'|translate}}
                </span>
                <mat-icon class="ml-1">fast_forward</mat-icon>
                </button>
            </div>
            <div class="next" *ngIf = "FormData.wczytywanie">
                
                <button mat-flat-button    type="button"  color="primary" 
                class="float-right szary" [name]="CurrentStep" >
                <span id="czekaj" [translation]="TranslationEnum.TranslationForm">
                    {{'czekaj'|translate}}
                </span>
                <mat-icon class="ml-1">create</mat-icon>
                </button>
            </div>
        </ng-container>
    </div>
</ng-container>
<div class="wczytywanie2" *ngIf = "FormData.wczytywanie">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>
