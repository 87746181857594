
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationService } from '../../module-login/authorization.service';
import { UserPermission } from '../../shared/models/user/user-permission.model';
import { User } from '../../shared/models/user/user.model';
import { TresciService } from '../../tresci.service';
import { UserService } from '../../user.service';
import { DateAdapter } from '@angular/material/core';
import { PermissionEnum } from 'src/app/shared/enums/permission-enum';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Contractor } from 'src/app/shared/models/user/contractor.model';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { ReklamacjeService } from '../reklamacje.service';
import { Complaints } from '../../shared/models/reklamacje/Complaints.model'
import { DataSharingService } from '../../shared/service/data-sharing.service';
import { merge, Observable, Subscription } from 'rxjs';
import { catchError, finalize, map, takeWhile } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatSort, Sort} from '@angular/material/sort';
import { Status } from '../../shared/models/reklamacje/Status.model';
import { Claim } from 'src/app/shared/models/reklamacje/Claim.model';
import { Invoice } from 'src/app/shared/models/reklamacje/Invoice.model';
import { Raport } from 'src/app/shared/models/reklamacje/Raport.model';
import { Complaint, Files, Position } from 'src/app/shared/models/reklamacje/Complaint.model';
import { PositionP } from 'src/app/shared/models/reklamacje/Position.model'
import { runInThisContext } from 'vm';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UniversalDialogComponent } from 'src/app/shared/components/universal-dialog/universal-dialog.component';
import { DialogData } from 'src/app/shared/models/dialog/dialog-data.model';
import { Consultation } from 'src/app/shared/models/reklamacje/Consultation.model';


@Component({
  selector: 'app-reklamacje-poz',
  templateUrl: './reklamacje-poz.component.html',
  styleUrls: ['./reklamacje-poz.component.scss']
})
export class ReklamacjePozComponent implements OnInit, OnDestroy {
  public User: User;
  public culture: string;
  public IsLoadingResults = true;
  public IsRateLimitReached = false;
  private contractorNumber = '';
  public numer:string;
  public add:boolean = false;
  public complaint:Complaint;
  public complaintString:string;
  public TranslationEnum = TranslationEnum;
  public claims:Claim[];
  public wybrany_claim:Number;
  public invoices:Invoice[];
  public invoicesDataSource;
  public raports:Raport[];
  public positions:PositionP[];
  public dodaje_poz:boolean = false;
  public dodaje_rap:boolean = false;
  public dodaje_fak:boolean = false;
  public displayedColumns = ['Id','System', 'OrderLp', 'ModuleLp', 'ItemLp', 'OrderNo',  'Drive','Width','Height','ConstructionColor','Fabric','FabricName', 'Quantity', 'QuantityComplaint'];
  public displayedColumnsInvoice = ['Date','InvoiceNumber', 'ProductionNumber', 'B2BNumber', 'OrderDetails'];
  
  //public displayedColumns: string[] = ['Id','System'];
  public invoice_wybrane:Invoice;
  public dodawana_pozycja:Position;
  public dodawany_raport:Raport[];
  public file_tmp:Files;
  public files_dodaj:Files[] = [];
  public indeks_file:number;
  public files_input:any;
  public pokaz_button_dodaj:boolean = false;
  public adresy_emial:string[] = [];
  public email_tmp:string = "";
  public pokaz_kons:boolean = false;
  public konsultacje:Consultation[];
  public nowa_konsultacja:Consultation = new Consultation();
  public ilosc_etykiet = 1;
  private subscriptions: Subscription[] = [];

  @ViewChild('fileform') fileform;
  @ViewChild('filekons') filekons; 

  
  public blob:Blob;

  constructor(private http: HttpClient, private reklamacjeService: ReklamacjeService,public dialog: MatDialog,private _adapter: DateAdapter<any>
    ,private dataSharingService: DataSharingService,public tresci: TresciService,private _changeDetectorRef: ChangeDetectorRef, public user: UserService,private authorizationService: AuthorizationService, 
    translationService: TranslateService, private route: ActivatedRoute, private router: Router) {
      this.subscriptions.push(this.authorizationService.User.subscribe((user) => {
        this.User = user;
        this.numer = this.route.snapshot.paramMap.get('numer');
        if(this.numer == "Dodaj"){
          this.add = true;

        }
      
        // this.EditingOrderspermission = this.authorizationService.hasPermission(PermissionEnum.EditingOrders);
      }));
      this.culture = window['culture'];
      this._adapter.setLocale(this.culture);

      this.subscriptions.push(translationService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.culture = window['culture'];
        this._adapter.setLocale(this.culture);
        this._changeDetectorRef.detectChanges();
      }));

      const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;

      this.contractorNumber = contractor.ContractorNumber;
      this.nowa_konsultacja.Context = "";
    }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

    ngAfterViewInit(): void {
      this.subscriptions.push(this.dataSharingService.contractorChanged.subscribe((x) => {
        if (x) {
          const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;

          if(!this.add && this.contractorNumber != contractor.ContractorNumber){
            this.contractorNumber = contractor.ContractorNumber;
            this.router.navigate(['Reklamacje']);
          } else {
            this.contractorNumber = contractor.ContractorNumber;
            this.adresy_emial = [];
            this.Wczytaj();
          }
        }
        
      }));
    }
  ngOnInit(): void {
    
      this.Wczytaj();
    
    
  }
  public dodajKomentarz(Id){
    this.nowa_konsultacja.ParentId = Id;
  }
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.invoicesDataSource.filter = filterValue.trim().toLowerCase();
  }

  public Wczytaj(){
    if(!this.add){
      this.subscriptions.push(this.reklamacjeService.GetComplaint(this.numer, this.culture).pipe(
        finalize(() => {
          this.IsLoadingResults = false;
        })).
      subscribe((data) => {
        this.complaint = data; 
        const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
        // console.log("trader", this.User.IsTrader);
        if(data.ContractorNumber != contractor.ContractorNumber && !this.User.IsTrader){
          this.router.navigate(['Reklamacje']);
        }
        var y = this.user;
        this.adresy_emial = this.complaint.ContactMail.split(";");
        var inv:Invoice = new Invoice();
        inv.B2BNumber = this.complaint.B2BNumber;
        inv.ContractorNumber = this.complaint.ContractorNumber; 
        inv.Date = "";
        inv.InvoiceNumber = this.complaint.InvoiceNumber;
        inv.OrderDetails = "";
        inv.ProductionNumber = this.complaint.ProductionNumber;
        inv.Year = Number.parseInt(this.complaint.Year);
        this.invoice_wybrane = inv;
        this.invoices = [];
        this.invoices.push(inv);

        var cl:Claim = new Claim();
        cl.Id = this.complaint.ClaimId;
        cl.Name = this.complaint.ClaimName;
        if(this.complaint.ClaimContent != null && this.complaint.ClaimContent != ""){
          cl.IsTextEnabled = true;
          this.complaint.ClaimIsTextEnabled = true;
        } else {
          cl.IsTextEnabled = false;
          this.complaint.ClaimIsTextEnabled = false;
        }
        this.claims = [];
        this.claims.push(cl);


        this.complaintString = JSON.stringify(data);
      }, (err: any) => {
        this.router.navigate(['Reklamacje']);
      }));
    } else {
      this.subscriptions.push(this.reklamacjeService.GetComplaintClaims(this.culture).pipe(
        finalize(() => {
          this.WczytajListeFaktur();
        })).
      subscribe((data) => {
        this.claims = data; 
      }));
    }
  }
  public WczytajListeFaktur(){
    this.subscriptions.push(this.reklamacjeService.GetComplaintInvoices(this.contractorNumber).pipe(
      finalize(() => {
        this.IsLoadingResults = false;
        
      })).
    subscribe((data) => {
      this.invoices = data.sort((x) => x.Date).reverse();
      this.invoicesDataSource = new MatTableDataSource<Element>(data);
      this.complaint = new Complaint(); 
      this.complaint.Positions = [];
      this.complaint.ContactName = this.User.Name;
      this.complaint.ContactMail = this.User.ContactMail;
      this.adresy_emial = [];
      this.adresy_emial.push(this.User.ContactMail);
      if(this.User.ContactPhone == undefined || this.User.ContactPhone == 'undefined'){
        this.complaint.ContactPhone = "";
      } else {
        this.complaint.ContactPhone = this.User.ContactPhone;
      }
      
      this.complaint.ShortDescription = "";
      this.complaint.LongDescription = "";
      this.complaint.Files = [];
    }));
  }
  public WczytajListeUsterek(){
    this.subscriptions.push(this.reklamacjeService.GetComplaintReports(this.complaint.ProductionNumber, this.culture).pipe(
      finalize(() => {
        //this.IsLoadingResults = false;
        
      })).
    subscribe((data) => {
      this.raports = data;
      this.WczytajListePozycji();
    }));
  }
  public WczytajListePozycji(){
    this.subscriptions.push(this.reklamacjeService.GetComplaintPositions(this.complaint.ProductionNumber).pipe(
      finalize(() => {
        this.IsLoadingResults = false;
        
      })).
    subscribe((data) => {
      data.forEach(x => x.QuantityComplaint = 0);
      this.positions = data; 
    }));
  }
  public UsunPlik(i){
    this.complaint.Files.splice(i, 1);
  }
  public UsunPoz(i){
    this.complaint.Positions.splice(i, 1);
  }
  public usunEmail(i) {
    if(this.adresy_emial.length > 1){
      this.adresy_emial.splice(i, 1); 
      var adresy: string = "";
      this.adresy_emial.forEach(element => {
        if (adresy == ""){
          adresy += element;
        } else {
          adresy += ";" + element;
        }
        
      });
      this.complaint.ContactMail = adresy;
      if(!this.add){
        this.IsLoadingResults = true;
        this.subscriptions.push(this.reklamacjeService.UpdateComplaint(this.complaint).pipe(
          finalize(() => {
            this.IsLoadingResults = false;
            
          })).
        subscribe((data) => {
          
        }));
      }
      

    } else {
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
            const data: DialogData = {
              title: '',
              content: 'conajmniejjedenemail',
              answerOk: true,
            };
            dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
    }
    
  }
  public WyslijKonsultacje() {
    if (this.nowa_konsultacja.Context != ''){
      this.nowa_konsultacja.ComplaintId = this.complaint.Id;
      this.nowa_konsultacja.Login = this.User.Username;
      
      this.IsLoadingResults = true;
      this.subscriptions.push(this.reklamacjeService.AddComplaintConsultation(this.culture, this.nowa_konsultacja).pipe(
        finalize(() => {
          
        })).
      subscribe((data) => {
        this.IsLoadingResults = true;
        this.subscriptions.push(this.reklamacjeService.GetComplaintConsultation(this.complaint.Id).pipe(
          finalize(() => {
            this.IsLoadingResults = false;
          })).
        subscribe((data) => {
          this.konsultacje = data;
          this.nowa_konsultacja = new Consultation();
          this.nowa_konsultacja.Context = "";
        }));
      }));
    }
    
  }





  public dodajEmail(){
   
      var pattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
      if(this.email_tmp.match(pattern)){
        this.adresy_emial.push(this.email_tmp);
        this.email_tmp = "";
        var adresy: string = "";
        this.adresy_emial.forEach(element => {
          if (adresy == ""){
            adresy += element;
          } else {
            adresy += ";" + element;
          }
        });
        this.complaint.ContactMail = adresy;
        if(!this.add){
          this.IsLoadingResults = true;
          this.subscriptions.push( this.reklamacjeService.UpdateComplaint(this.complaint).pipe(
            finalize(() => {
              this.IsLoadingResults = false;
              
            })).
          subscribe((data) => {
            
          }));
        }
        

      } else {
        let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
            const data: DialogData = {
              title: '',
              content: 'nieprawidlowyadresemail',
              answerOk: true,
            };
            dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
      }
  }
  public ZmienFaktureButton(){
    if(this.add){
      this.dodaje_fak = true;
      this.invoicesDataSource.filter = "";
    }
    
  }
  public ustawFak(e: any) {
    
    var inv:Invoice = e; //this.invoices.find(x=>x.InvoiceNumber == e.value);
    this.complaint.InvoiceNumber = inv.InvoiceNumber;
    this.complaint.ProductionNumber = inv.ProductionNumber;
    this.complaint.B2BNumber = inv.B2BNumber;
    this.complaint.OrderDetails = inv.OrderDetails;
    this.complaint.TraderOrder = inv.TraderOrder; 
    this.complaint.Date = inv.Date;
    this.IsLoadingResults = true;
    this.complaint.Positions = [];
    this.dodaje_fak = false;
    this.WczytajListeUsterek(); 
    
  }
  public ZmienFakture(e: any) {
     
    var inv:Invoice = e.value; //this.invoices.find(x=>x.InvoiceNumber == e.value);
    this.complaint.InvoiceNumber = inv.InvoiceNumber;
    this.complaint.ProductionNumber = inv.ProductionNumber;
    this.complaint.B2BNumber = inv.B2BNumber;
    this.complaint.OrderDetails = inv.OrderDetails;  
    this.complaint.TraderOrder = inv.TraderOrder;
    this.IsLoadingResults = true;
    this.complaint.Positions = [];
    this.WczytajListeUsterek();
    
    
  }
  public ZmienIloscEtykiet(e: any){
    this.ilosc_etykiet = e.target.value;
  }
  public ZmienKons(e: any){
    this.nowa_konsultacja.Context = e.target.value;
  }
  public ZmienShort(e: any){
    this.complaint.ShortDescription = e.target.value;
  }
  public ZmienLong(e: any){
    this.complaint.LongDescription = e.target.value;
  }
  
  public ZmienPhone(e: any){
    this.complaint.ContactPhone = e.target.value;
  }
  public ZmienName(e: any){
    this.complaint.ContactName = e.target.value;
  }
  public ZmienClaim(e: any){
    this.complaint.ClaimContent = e.target.value;
  }
  public zmienIlosc(e:any, ilosc:number){
    if(e.QuantityComplaint > ilosc){
      e.QuantityComplaint = ilosc;
    }
  }
  public zmienKarte(e: any){
    
    if(e.index == 0){
      this.pokaz_kons = false;
      this.Wczytaj();
    } else {
      this.pokaz_kons = true;
      this.IsLoadingResults = true;
      this.subscriptions.push(this.reklamacjeService.GetComplaintConsultation(this.complaint.Id).pipe(
        finalize(() => {
          this.IsLoadingResults = false;
          
        })).
      subscribe((data) => {
        this.konsultacje = data;

      }));

    }
    
  }
  public PobierzListePrzew(){
    
    this.IsLoadingResults = true;
    this.subscriptions.push(this.reklamacjeService.GetPrintShippingList(this.culture, this.complaint.Id).pipe(
      finalize(() => {
        this.IsLoadingResults = false;
        
      })).
    subscribe((data) => {
      var x = data;
      var downloadURL = window.URL.createObjectURL(data);
      window.open(downloadURL,'_blank');
    }));
    
  }
  public PobierzEtykiety(){
    this.IsLoadingResults = true;
    this.reklamacjeService.GetPrintLabel(this.culture, this.complaint.Id, this.ilosc_etykiet).pipe(
      finalize(() => {
        this.IsLoadingResults = false;
        
      })).
    subscribe((data) => {
      var downloadURL = window.URL.createObjectURL(data);
      window.open(downloadURL,'_blank');
    });
  }
  public ZmienEmail(e: any){
    this.email_tmp = e.target.value;
  }
  public wszystkiePozycje(){
    this.positions.forEach(x => x.QuantityComplaint = x.Quantity);
  }
  public zadnePozycje(){
    this.positions.forEach(x => x.QuantityComplaint = 0);
  }
  public ZmienRoszczenie(e: any) {
    this.complaint.ClaimId = e.value;
    var cla:Claim = this.claims.find(x=>x.Id == e.value);
    this.complaint.ClaimName = cla.Name;
    this.complaint.ClaimContent = "";
    this.complaint.ClaimIsTextEnabled = cla.IsTextEnabled;
  }

  public DodajPozycje(){
    this.dodawany_raport = [];
    this.dodawany_raport.push(new Raport());
    this.dodawany_raport.push(new Raport());
    this.dodawany_raport.push(new Raport());
    this.dodaje_rap = true;
  }
  public ZmienRaport(e: any, poziom:any) {
    
    this.dodawana_pozycja = new Position();
    this.dodawana_pozycja.MaxPoz = 3;
    this.dodawana_pozycja.ReportId = e.value;

    for(let x = poziom;x<= 2;x++){
        this.dodawany_raport[x].Id = null;
    }
    var rapParent:Raport[] = this.raports.filter(x=>x.ParentId == e.value);
    if(rapParent.length == 0){
        var rap:Raport = this.raports.find(x=>x.Id == e.value);
        this.dodawana_pozycja.ReportName = rap.Name;
        this.dodawana_pozycja.MaxPoz = poziom;
    } else {
      this.dodawana_pozycja.ReportName = null;
      this.dodawana_pozycja.MaxPoz = 3;
    }
  }
  public DodajRaport() {
    this.dodaje_rap = false;
    this.dodaje_poz = true;
  }
  public ZakonczPozycje() {
    var ilosc:any = 0;
    this.positions.forEach(element => {
      if(element.QuantityComplaint != null){
        ilosc = element.QuantityComplaint + ilosc;
      }
    });
    if (ilosc > 0){
      this.dodaje_rap = false;
      this.dodaje_poz = false;
      this.positions.forEach(element => {
        if(element.QuantityComplaint != null && element.QuantityComplaint > 0){
            var poz = new Position();
            poz.Module = element.ModuleLp;
            poz.OrderLp = element.OrderLp;
            poz.Position = element.ItemLp;//Number.parseInt(element.Poz);
            poz.ProductionNumber = this.complaint.ProductionNumber;
            poz.QuantityComplaint = element.QuantityComplaint;
            poz.ReportId= this.dodawana_pozycja.ReportId;
            poz.ReportName = this.dodawana_pozycja.ReportName;
            this.complaint.Positions.push(poz);
            
        }
      });
    } else {
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
            const data: DialogData = {
              title: '',
              content: 'niewybranopozycji',
              answerOk: true,
            };
            dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
    }
    
  }

  public DodajPliki(){
    this.files_dodaj = [];
    this.files_dodaj = this.complaint.Files.filter(x => x.add == true);
    this.IsLoadingResults = true;
    this.subscriptions.push(this.reklamacjeService.AddFile(this.complaint.Id, this.files_dodaj).pipe(
      finalize(() => {
        this.IsLoadingResults = false;
        
      })).
    subscribe((data) => {
      // this.positions = data; 
      this.pokaz_button_dodaj = false;
      
      this.files_dodaj.forEach(element => {
        element.add_button = false; 
      });
    }));
  }

  public pokazPLikKonsultacje(Id:number){
    this.subscriptions.push(this.reklamacjeService.GetFile(this.culture, Id).subscribe((data) => {

      // this.blob = new Blob([data], {type: 'application/x'});
      var downloadURL = window.URL.createObjectURL(data);
      window.open(downloadURL,'_blank');
    }));
  }
  public PokazPlik(plik:Files){
    var x = plik;
    if(plik.add == true){
      // this.blob = new Blob(plik.file, {type: 'application/x'});
      var f = plik.file.split("base64,");
      const byteCharacters = atob(f[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {type: plik.Type});
      var downloadURL = window.URL.createObjectURL(blob);
      
      // console.log(plik);
      // window.open(downloadURL,'_blank');

      var fileLink = document.createElement('a');
      fileLink.href = downloadURL;
      fileLink.target = "_blank"; 
      fileLink.download = plik.Name;
      fileLink.click();

    } else {
      this.subscriptions.push(this.reklamacjeService.GetFile(this.culture, plik.Id).subscribe((data) => {

        // this.blob = new Blob([data], {type: 'application/x'});
      
        var downloadURL = window.URL.createObjectURL(data);
        //window.open(downloadURL,'_blank');
        
        var fileLink = document.createElement('a');
        fileLink.href = downloadURL;
        fileLink.target = "_blank"; 
        fileLink.download = plik.Name;
        fileLink.click();
      }));
    }
  } 
  public ZapiszRap(){
    if(this.add && this.complaint.Positions && this.complaint.Positions[0] && this.complaint.ClaimId && this.complaint.ShortDescription != '' && this.complaint.LongDescription != '' && this.complaint.ContactMail!='' && this.complaint.ContactPhone.trim()!='' && (!this.complaint.IsTrader || !this.complaint.TraderOrder || this.complaint.Files.length > 0)){
      
      this.IsLoadingResults = true;
      this.complaint.ContractorNumber = this.contractorNumber;
      this.subscriptions.push(this.reklamacjeService.AddorUpdateComplaint(this.culture, this.complaint).pipe(
        finalize(() => {
          this.IsLoadingResults = false;
          
        })).
      subscribe((data) => {
        this.router.navigate(['Reklamacje']);
      },(error) => {
        let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
        const data: DialogData = {
          title: '',
          content: 'bladzapisu',
          answerOk: true,
        };
        dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
      } ));
    } else {
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
      const data: DialogData = {
        title: '',
        content: 'nie wszystkie wymagane dane zostały uzupełnione',
        answerOk: true,
      };
      dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });


    }
    
  }
  onFileSelected(event) {
    if(this.files_input == null){
      this.indeks_file = 0;
      this.files_input = event.target.files;
      this.lodadFile();
    }
  }
  onFileSelected2(event) {
    if(this.nowa_konsultacja.Name == null){
      var file:File = event.target.files[0];
      if(file){
        this.nowa_konsultacja.Name = file.name;
        this.nowa_konsultacja.Size = file.size;
        this.nowa_konsultacja.Type = file.type;
        this.lodadFile2(file);
      }
    }
  }
  public lodadFile2(file:File){
      if (file) {

        var pattern = 'text.*|image.*|application.pdf|video.*|audio.*';
        // const formData = new FormData();
        var reader = new FileReader();
        if (file.size > 20 * 1024 * 1024) {
          // alert('file size');
          
          let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
          const data: DialogData = {
            title: '',
            content: 'zaduzyplik',
            answerOk: true,
          };
          dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
          this.nowa_konsultacja = new Consultation();
          this.filekons.nativeElement.reset();
          return;
        }
        var x = file.type;
        if (!file.type.match(pattern)) {
          // alert('invalid format');
          
          let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
          const data: DialogData = {
            title: '',
            content: 'zlerozszerzenie',
            answerOk: true,
          };
          dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
          this.nowa_konsultacja = new Consultation();
          this.filekons.nativeElement.reset();
          return;
        }
        
        
        
        
        reader.onload = this._handleReaderLoaded2.bind(this);
        reader.readAsDataURL(file);
        //formData.append("thumbnail", file);

        //const upload$ = this.http.post("/api/thumbnail-upload", formData);

        //upload$.subscribe((data) => {
        //  var x = data;
        //});
    } else {
      this.nowa_konsultacja = new Consultation();
      this.filekons.nativeElement.reset();

    }
  
}

public NacisnijDodajPlik() {
  let element:HTMLElement = document.getElementById('fileformInput') as HTMLElement;
  element.click();
  
}
public NacisnijDodajPlik2() {
  let element:HTMLElement = document.getElementById('fileformInput2') as HTMLElement;
  element.click();
  
}
  public lodadFile(){
      const file:File = this.files_input[this.indeks_file];
        if (file) {

          var pattern = 'text.*|image.*|application.pdf|video.*|audio.*';
          // const formData = new FormData();
          var reader = new FileReader();
          var rozmiar = 0;
          this.complaint.Files.forEach(f => {
            rozmiar += f.Size;
          });

          

          if (file.size + rozmiar > 20 * 1024 * 1024) { 
            // alert('file size');
            
            let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
            const data: DialogData = {
              title: '',
              content: 'zaduzyplik',
              answerOk: true,
            };
            dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
            this.files_input = null;
            this.fileform.nativeElement.reset();
            return;
          }
          if (!file.type.match(pattern)) {
            // alert('invalid format');
            
            let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
            const data: DialogData = {
              title: '',
              content: 'zlerozszerzenie',
              answerOk: true,
            };
            dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
            this.files_input = null;
            this.fileform.nativeElement.reset();
            return;
          }
          this.file_tmp = new Files();
          this.file_tmp.Name = file.name;
          this.file_tmp.Size = file.size;
          this.file_tmp.Type = file.type;
          this.file_tmp.add = true;
          this.file_tmp.add_button = true;
          
          
          
          reader.onload = this._handleReaderLoaded.bind(this);
          reader.readAsDataURL(file);
          //formData.append("thumbnail", file);

          //const upload$ = this.http.post("/api/thumbnail-upload", formData);

          //upload$.subscribe((data) => {
          //  var x = data;
          //});
      } else {
        this.fileform.nativeElement.reset();
        this.files_input = null;
      }
    
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.file_tmp.file = reader.result;
    // console.log(this.file_tmp);
    this.complaint.Files.push(this.file_tmp);
    if (!this.add){
      this.pokaz_button_dodaj = true;
    }
    this.indeks_file++;
    this.lodadFile();
    
  }
  _handleReaderLoaded2(e) {
    let reader = e.target;
    // console.log(reader.result);
    this.nowa_konsultacja.File = reader.result;
    this.nowa_konsultacja.ComplaintId = this.complaint.Id;
    this.nowa_konsultacja.Login = this.User.Username;
    this.subscriptions.push(this.reklamacjeService.AddComplaintConsultation(this.culture, this.nowa_konsultacja).pipe(
      finalize(() => {
        
      })).
    subscribe((data) => {
      this.IsLoadingResults = true;
      this.subscriptions.push(this.reklamacjeService.GetComplaintConsultation(this.complaint.Id).pipe(
        finalize(() => {
          this.IsLoadingResults = false;
        })).
      subscribe((data) => {
        this.konsultacje = data;
        this.nowa_konsultacja = new Consultation();
        this.nowa_konsultacja.Context = "";
      }));
    }));
  }
  public zamknijOkno() {
    this.dodaje_rap = false;
    this.dodaje_poz = false;
    this.dodaje_fak = false;
  }
  public nieZamykajOkna(event:any) {
    event.stopPropagation();
  }




}
