import { AuthorizationService } from './../../../module-login/authorization.service';
import { OrderEstimate } from './../../../shared/models/order-register/order-estimate/order-estimate';
import { Country } from './../../../shared/models/order-register/order-estimate/country.model';
import { Component, Inject, OnDestroy, OnInit, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { ClientInfo } from 'src/app/shared/models/client/client-info.model';
import { OrderList } from 'src/app/shared/models/order-register/order-list/order-list.model';
import { OrderRegisterService } from '../../order-register.service';
import { MatTableDataSource } from '@angular/material/table';
import { AdditionalItem } from 'src/app/shared/models/order-register/order-estimate/additional-item';
import { DOCUMENT } from '@angular/common';
import { Contractor } from 'src/app/shared/models/user/contractor.model';
import { Subscription } from 'rxjs';
import { TresciService } from 'src/app/tresci.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-order-estimate-dialog',
  templateUrl: './order-estimate-dialog.component.html',
  styleUrls: ['./order-estimate-dialog.component.scss']
})
export class OrderEstimateDialogComponent implements OnInit, OnDestroy {

  public TranslationEnum = TranslationEnum;

  public EstimateGroup: FormGroup;
  public AdditionalItemsGroup: FormGroup;

  public OrderEstimate: OrderEstimate;
  private subscriptions: Subscription[] = [];

  calculateVATForTotal = new FormControl(false);
  dataSource: MatTableDataSource<AdditionalItem> = new MatTableDataSource<AdditionalItem>();
  additionalItems: AdditionalItem[] = [];
  public CountriesList: Country[] = [];


  displayedColumns: string[] = [
    'Name', 'Quantity', 'Price', 'Vat', 'Discount', 'Remove'
  ];

  public ListClientOfContractors: ClientInfo[];


  constructor(public dialogRef: MatDialogRef<OrderEstimateDialogComponent>, @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: OrderList, private fb: FormBuilder, public orderRegisterService: OrderRegisterService, public tresci: TresciService, public translateService: TranslateService) {
    this.ListClientOfContractors = new Array<ClientInfo>();
    const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
    this.OrderEstimate = {
      ActiveFormOrderId: data.Id,
      ContractorNumber: contractor.ContractorNumber
    };
    this.EstimateGroup = this.fb.group({
      produkt: [this.data.Produkt],
      contractorNumberOrder: [''],
      customerName: [''],
      nip: [''],
      zipCode: [''],
      city: [''],
      country: ['PL'],
      systemDiscount: [0],
      electricianDiscount: [0],
      accessoriesDiscount: [0],
      vatForTotal: [0],
      address: ['']
    });

    this.AdditionalItemsGroup = this.fb.group({
      name: ['', Validators.required],
      quantity: [0, Validators.required],
      price: [0, Validators.required],
      vat: [0, Validators.required],
      discount: [0, Validators.required]
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit(): void {
    this.EstimateGroup.controls['vatForTotal'].disable();
    //this.subscriptions.push(this.orderRegisterService.getCountriesForEstimateOrder().subscribe(x => this.CountriesList = x));
    this.getCountryList();
  }
  getCountryList() {
    var currentLang = window['culture'];
    var jezyk = 1; 
    for(var j = 0;j< this.translateService.langs.length; j++){
      if(this.translateService.langs[j] === currentLang){
        jezyk = j+1;
      }
    }
    this.orderRegisterService.getCountriesForEstimateOrder(jezyk).
    subscribe((data) => {
      // console.log("kraje", data);
      this.CountriesList = data;
      
    });
  }
  changeControl() {
    this.EstimateGroup.controls['vatForTotal'].setValue(0);
    this.EstimateGroup.controls['vatForTotal'].enable();
  }


  addAdditionalItem({ value, valid }: { value: any, valid: boolean }) {
    if (valid) {
      const additionalItem: AdditionalItem = {
        Name: value.name,
        Quantity: value.quantity,
        Price: value.price,
        Vat: value.vat,
        Discount: value.discount
      };

      this.additionalItems.push(additionalItem);
      this.dataSource.data = [];
      this.dataSource.data = this.additionalItems;

      this.AdditionalItemsGroup = this.fb.group({
        name: ['', Validators.required],
        quantity: [0, Validators.required],
        price: [0, Validators.required],
        vat: [0, Validators.required],
        discount: [0, Validators.required]
      });
    }
  }

  removeAdditionalItem(additionalItem: any) {
    const index = this.additionalItems.indexOf(additionalItem);
    this.additionalItems.splice(index, 1);
    this.dataSource.data = [];
    this.dataSource.data = this.additionalItems;
  }

  onSubmit({ value, valid }: { value: any, valid: boolean }) {
    if (valid) {
      this.orderRegisterService.sendMailWithOrder(value);
    }
  }


  getValuation({ value, valid }): void {
    if (valid) {
      this.OrderEstimate.Produkt = value.product;
      this.OrderEstimate.CustomerOrderNumber = value.contractorNumberOrder;
      this.OrderEstimate.Name = value.customerName;
      this.OrderEstimate.Nip = value.nip;
      this.OrderEstimate.Address = value.address;
      this.OrderEstimate.ZipCode = value.zipCode;
      this.OrderEstimate.City = value.city;
      this.OrderEstimate.Country = value.country;
      this.OrderEstimate.SystemDiscount = value.systemDiscount;
      this.OrderEstimate.ElectricityDiscount = value.electricianDiscount;
      this.OrderEstimate.AccessoryDiscount = value.accessoriesDiscount;
      this.OrderEstimate.VatForOrder = value.vatForTotal;
      this.OrderEstimate.OrderAdditives = this.additionalItems;

      this.subscriptions.push(this.orderRegisterService.getValuationForEstimateOrder(this.OrderEstimate).subscribe((x: Blob) => this.openPdf(x)));
    }
  }

  getSpecification({ value, valid }): void {
    if (valid) {
      this.OrderEstimate.Produkt = value.product;
      this.OrderEstimate.CustomerOrderNumber = value.contractorNumberOrder;
      this.OrderEstimate.Name = value.customerName;
      this.OrderEstimate.Nip = value.nip;
      this.OrderEstimate.Address = value.address;
      this.OrderEstimate.ZipCode = value.zipCode;
      this.OrderEstimate.City = value.city;
      this.OrderEstimate.Country = value.country;
      this.OrderEstimate.SystemDiscount = value.systemDiscount;
      this.OrderEstimate.ElectricityDiscount = value.electricianDiscount;
      this.OrderEstimate.AccessoryDiscount = value.accessoriesDiscount;
      this.OrderEstimate.VatForOrder = value.vatForTotal;
      this.OrderEstimate.OrderAdditives = this.additionalItems;
      this.subscriptions.push(this.orderRegisterService.getSpecificationForEstimateOrder(this.OrderEstimate).subscribe((x: Blob) => this.openPdf(x)));
    }
  }

  openPdf(blob: Blob): void {
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = URL.createObjectURL(blob);
    link.click();
    link.remove();
  }

}
