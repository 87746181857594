<h2><span id="ustawienia_slupa" [translation]="TranslationEnum.Translation">{{'ustawienia_slupa'|translate}}</span> {{pergola.edytuj + 1}}</h2>

<ng-container *ngIf = "pergola.edytuj >= 0 && pergola.edytuj < pergola.slupy.length">
    <button  mat-flat-button  type="button" color="accent" id="usun_slup" class="btn-sm float-right"
    [translation]="TranslationEnum.TranslationForm" (click) = "usunSlup()">
    {{'usun_slup'|translate}}
    </button>

    <div class="pole_edycji">
        <div class="nazwa"><span id="połozeniex" [translation]="TranslationEnum.Translation">{{'połozeniex'|translate}}</span></div>
        <div class="wartosc"><input  name="pozx"  [(ngModel)]="pergola.slupy[pergola.edytuj].pozx" (change)="sprawdz(pergola.slupy[pergola.edytuj])"> mm</div>
    </div>
    <div class="pole_edycji">
        <div class="nazwa"><span id="połozeniey" [translation]="TranslationEnum.Translation">{{'połozeniey'|translate}}</span></div>
        <div class="wartosc"><input  name="pozy"  [(ngModel)]="pergola.slupy[pergola.edytuj].pozy" (change)="sprawdz(pergola.slupy[pergola.edytuj])"> mm</div>
    </div>
    <div class="pole_edycji">
      <div class="nazwa"><span id="jakastopa" [translation]="TranslationEnum.Translation">{{'jakastopa'|translate}}</span></div>
      <div class="wartosc">
        <form [formGroup]="form">
          <mat-form-field>
              <mat-select (selectionChange) = "changeSelect($event)" formControlName="state" width = "auto">
                  <ng-container  *ngFor="let v of pergola.ListaStopy; let kk = index;">
                      <mat-option [value]="v">{{v}}</mat-option>
                  </ng-container>
              
              </mat-select>
          </mat-form-field>
      </form>
      </div>
      
    </div>
    
</ng-container>

<div mat-dialog-actions>
    <div class="w-100">
      <button mat-flat-button id="akceptuj" class="btn-sm float-right" [translation]="TranslationEnum.Translation"
        color="primary" [mat-dialog-close]="true">
        {{'ok'|translate}}
      </button>
    </div>
  </div>