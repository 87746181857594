<div class="adresy aktywny{{FormData.delivery.AdressEdit}}">
    <h3>
        <span id="dostawa" [translation]="TranslationEnum.TranslationForm">{{ 'dostawa' | translate }}</span>
    </h3>
    <mat-button-toggle-group  [disabled]="!FormData.delivery.AdressEdit" class="border-0 rounded-0 mt-3">
        <mat-button-toggle [checked]="FormData.delivery.DeliveryMethod.Id === 2" (click)="ZmienDelivery(0)" id="odbior_wlasny" [translation]="TranslationEnum.TranslationForm">
          <img #ownCollection class="d-block m-auto" height="50px" width="35px" src="../../../assets/images/own_collection_{{ FormData.delivery.DeliveryMethod.Id === 2 }}.svg" />
          {{ 'odbior_wlasny' | translate }}
        </mat-button-toggle>
        <mat-button-toggle [checked]="FormData.delivery.DeliveryMethod.Id === 3" (click)="ZmienDelivery(1)" id="selt" [translation]="TranslationEnum.TranslationForm">
          <img #selt class="d-block m-auto" height="50px" width="30px" src="../../../assets/images/delivery_selt_{{ FormData.delivery.DeliveryMethod.Id === 3 }}.svg" />
          {{ 'selt' | translate }}
        </mat-button-toggle>
        <mat-button-toggle *ngIf="contractor.Country !== 'PL' || FormData.IsTrader" [checked]="FormData.delivery.DeliveryMethod.Id === 4" (click)="ZmienDelivery(2)" id="spedytor" [translation]="TranslationEnum.TranslationForm">
          <img #forwarder class="d-block m-auto" width="50px" src="../../../assets/images/forwarder_{{ FormData.delivery.DeliveryMethod.Id === 4 }}.svg" />
          {{ 'spedytor' | translate }}
        </mat-button-toggle>
      </mat-button-toggle-group>


      <ng-container *ngIf="FormData.delivery.DeliveryMethod.IsDeliveryAddressRequired">
        <h3 class="pl-2 mt-3 mb-2">
          <span id="adres_dostawy" [translation]="TranslationEnum.TranslationForm">
            {{ 'adres_dostawy' | translate }}
          </span>
          <app-information [label]="'adres_dostawy'" [fileInfoId]="'plik_info_adres_dostawy_' + FileInfoId"></app-information>
        </h3>
        <div class="row">
          <div class="col-12">
            
            <mat-form-field appearance="outline" class="w-100" color="accent">
              <mat-select [errorStateMatcher]="matcher"  [(ngModel)]="FormData.delivery.ChosenAdresses"  [formControl]="deliveryAddressControl" (selectionChange)="zmienAdres($event.value)"  [disabled]="!FormData.delivery.AdressEdit" >
                <ng-container *ngFor="let item of FormData.delivery.ContractorAddresses" >
                  <mat-option [value]="item" *ngIf ="FormData.delivery.DeliveryMethod.Id !== 3 || !item.OtherTransportThenSelt">
                    {{ item.Comment }}
                    {{ item.Address }}
                    {{ item.ZipCode }}
                    {{ item.City }}
                    {{ item.Country }}
                    ({{ item.Zone }})</mat-option>
                </ng-container>
                
              </mat-select>
              <mat-error *ngIf="deliveryAddressControl.hasError('required')" id="musisz_wybrac_adres_dostawy" [translation]="TranslationEnum.TranslationForm">
                {{ 'musisz_wybrac_adres_dostawy' | translate }}
              </mat-error>
              <mat-error *ngIf="FormData.delivery.ContractorAddresses.length <= 0" id="dodaj_nowy_adres_dostawy_w_celu_utworzenia_zamowienia" [translation]="TranslationEnum.TranslationForm">
                {{ 'dodaj_nowy_adres_dostawy_w_celu_utworzenia_zamowienia' | translate }}
              </mat-error>
              <mat-error *ngIf="!FormData.CzyPoprawnyAdres()" id="nie_poprawny_adres" [translation]="TranslationEnum.TranslationForm">
                {{ 'nie_poprawny_adres' | translate }}
              </mat-error>
    

              <!--<mat-error *ngIf="deliveryAddressControl.hasError('required')" id="musisz_wybrac_adres_dostawy" [translation]="TranslationEnum.TranslationForm">
                {{ 'musisz_wybrac_adres_dostawy' | translate }}
              </mat-error> -->
              
            </mat-form-field>
          </div>
        </div>
        <button class="edytuj_adres" *ngIf = "FormData.delivery.DeliveryMethod.Id !== 3" mat-flat-button color="primary" (click) = "otworzAdres()">
          <span id="zmien_adres"   class="enable{{!FormData.delivery.AdressEdit}}" [translation]="TranslationEnum.TranslationForm">
            {{ 'zmien_adres' | translate }}
           
          </span>
          <mat-icon >edit</mat-icon>
        </button>
      </ng-container>
     

      <div class="reklamacja" *ngIf = "CreateComplaintOrder">
        <input type="checkbox" [(ngModel)]="complaint_check"  [disabled]="!FormData.delivery.AdressEdit"
                (change)="zmien_check($event)" /> 
            <span id="czyreklamacja"   class="enable{{!FormData.delivery.AdressEdit}}" [translation]="TranslationEnum.TranslationForm">
              {{ 'czyreklamacja' | translate }}
            </span>
            
              <div class="wybor" *ngIf = "complaint_check && this.FormData.delivery.ComplaintTypes.length > 0">
                <div class="row">
                  <div class="col-12">
                <mat-form-field appearance="outline" class="w-100" color="accent" >
                  <mat-select [formControl]="complaintsControlTyp"  [(ngModel)]="FormData.delivery.ComplaintType"  [errorStateMatcher]="matcher" (selectionChange)="zmien_TypReklamacji($event.value)"  [disabled]="!FormData.delivery.AdressEdit">
                    <mat-option *ngFor="let item of FormData.delivery.ComplaintTypes" [value]="item">
                      <span id="item.Name.toLowerCase()" [translation]="TranslationEnum.TranslationForm">{{ item.Name.toLowerCase() | translate }}</span>
                      </mat-option>
                  </mat-select>
                  <mat-error *ngIf="complaintsControlTyp.hasError('required')" id="musisz_wybrac_typ_reklamacji" [translation]="TranslationEnum.TranslationForm">
                    {{ 'musisz_wybrac_typ_reklamacji' | translate }}
                  </mat-error>
                  <mat-error *ngIf="complaints.length <= 0" id="brak_typu_reklamacji" [translation]="TranslationEnum.TranslationForm">
                    {{ 'brak_typu_reklamacji' | translate }}
                  </mat-error>
                </mat-form-field>

              </div>
            </div>
          </div>
          <div class="filtr1" *ngIf = "complaint_check && this.FormData.delivery.complaints.length > 0">
            <div class="filtr">
              <input type="text" name = "filtr" [(ngModel)]="filtr" (change) = "zmienFiltr()" />
              </div>
            </div>
            
        
            <div class="wybor" *ngIf = "complaint_check && this.complaints.length > 0">
             
                  <div class="row">
                    <div class="col-12">
                  <mat-form-field appearance="outline" class="w-100" color="accent" >
                    <mat-select [formControl]="complaintsControl"  [errorStateMatcher]="matcher" (selectionChange)="zmienReklamacje($event.value)"  [disabled]="!FormData.delivery.AdressEdit">
                      <mat-option *ngFor="let item of complaints" [value]="item">
                        {{ item.ComplaintNumber }}
                        {{ item.ProductionNumber }}
                        {{ item.B2BNumber }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="complaintsControl.hasError('required')" id="musisz_wybrac_zlecenie_do_reklamacji" [translation]="TranslationEnum.TranslationForm">
                      {{ 'musisz_wybrac_zlecenie_do_reklamacji' | translate }}
                    </mat-error>
                    <mat-error *ngIf="complaints.length <= 0" id="brak_zlecen_do_reklamacji" [translation]="TranslationEnum.TranslationForm">
                      {{ 'brak_zlecen_do_reklamacji' | translate }}
                    </mat-error>
                  </mat-form-field>
    
                </div>
              </div>
            </div>
      </div>


      <div class="next">
        <button mat-flat-button color="primary" *ngIf = "FormData.delivery.AdressEdit"  (click) = "  next(tresci, formService, 'Adres')" class="float-right" [id]="'btn_'+CurrentStep"
        type="submit">
        <span id="dalej"[translation]="TranslationEnum.TranslationForm">
            {{'dalej'|translate}}
        </span>
        <mat-icon class="ml-1">forward</mat-icon>
        </button>
        <button mat-flat-button  *ngIf = "!FormData.delivery.AdressEdit && !FormData.wczytywanie"  (click) = "FormData.active(null, -1)" type="button"  color="primary" 
        class="float-right" [name]="CurrentStep" >
        <span id="edytuj" [translation]="TranslationEnum.TranslationForm">
            {{'edytuj'|translate}}
        </span>
        <mat-icon class="ml-1">create</mat-icon>
        </button>
      </div>
      

</div>