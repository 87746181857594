import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private cookieService: CookieService) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.cookieService.check('_token')) {
      var url = "";
      route.url.forEach(u => {
          if(u.toString() != 'rd'){
            url += u +"/";
            console.log(u);
          }
      });
      
      window['redirectto'] = url;
      this.router.navigate(['Login']);
    }
    return true;
  }
}
