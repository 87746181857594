import { WdtStatusEnum } from './../shared/enums/wdt-status-enum';
import { OrderEstimate } from './../shared/models/order-register/order-estimate/order-estimate';
import { OrderRegisterStatuesQuantity } from './../shared/models/order-register/order-register-statuses-quantity.model';
import { MailWithOrder } from './../shared/models/order-register/order-list/mail-with-order.model';
import { ProformsListData } from '../shared/models/order-register/proforms-list/proforms-list-data.model';
import { OrderListData } from '../shared/models/order-register/order-list/order-list-data.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatusEnum } from '../shared/enums/status-enum';
import { Observable } from 'rxjs';
import { Package } from '../shared/models/order-register/order-list/package.model';
import { TresciService } from '../tresci.service';
import { Country } from '../shared/models/order-register/order-estimate/country.model';
import { OrderVerify } from '../shared/models/orders/order-verify/order-verify.model';
import { Delivery } from '../shared/models/order-register/delivery-list/delivery.model';
import { WDTconfirm } from '../shared/models/order-register/order-list/WDTconfirm';

@Injectable({
  providedIn: 'root',
})
export class OrderRegisterService {
  public goToPage = 0;

  constructor(private httpClient: HttpClient, public tresci: TresciService) {}

  public getOrderStatusesQuantity(contractorNumber: string, isMineOrders: boolean, dateFrom: string, dateTo: string, ShowComplaints:boolean): Observable<OrderRegisterStatuesQuantity> {
    return this.httpClient.get<OrderRegisterStatuesQuantity>(
      'api/OrderRegister/GetOrderStatuses?contractorNumber=' + contractorNumber + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&isMineOrders=' + isMineOrders + '&ShowComplaints=' + ShowComplaints
    );
  }

  public getOrders(
    contractorNumber: string,
    status: StatusEnum,
    filterColumn: string,
    filterrTerm: string,
    sortColumn: string,
    sortDirection: string,
    pageIndex: number,
    pageSize: number,
    dateFrom: string,
    dateTo: string,
    isMineOrders: boolean,
    ShowComplaints:boolean
  ): Observable<OrderListData> {
    if (filterrTerm === '') {
      filterColumn = '';
    }

    return this.httpClient.get<OrderListData>(
      'api/OrderRegister/getOrders?contractorNumber=' +
        contractorNumber +
        '&statusId=' +
        status +
        '&searchColumn=' +
        filterColumn +
        '&searchTerm=' +
        filterrTerm +
        '&columnKeySort=' +
        sortColumn +
        '&kindSort=' +
        sortDirection +
        '&pageNumber=' +
        (pageIndex + 1) +
        '&itemPerPage=' +
        pageSize +
        '&dateFrom=' +
        dateFrom +
        '&dateTo=' +
        dateTo +
        '&isMineOrders=' +
        isMineOrders  +
        '&ShowComplaints=' +
        ShowComplaints
    );
  }

  public getAttachments(id: number): Observable<any[]> {
    return this.httpClient.get<any[]>('api/OrderRegister/GetFilesRegisterForOrder?orderId=' + id);
  }
  

  public getPackages(productionNumber: string): Observable<Package[]> {
    return this.httpClient.get<Package[]>('api/OrderRegister/GetPackages?productionNumber=' + productionNumber);
  }

  public getDelivery(productionNumber: string): Observable<Package[]> {
    return this.httpClient.get<Package[]>('api/OrderRegister/GetPackages?productionNumber=' + productionNumber);
  }

  public submitOrderForVerificationBySelt(id: number): Observable<OrderVerify[]> {
    return this.httpClient.post<any>(`api/OrderRegister/SubmitOrderForVerificationBySelt?Culture=${window['culture']}`, id);
  }

  public sendMailWithOrder(mail: MailWithOrder) {
    return this.httpClient.post<any>('api/OrderRegister/SendMailWithOrder', mail).subscribe();
  }

  public cancelOrders(ids: number[]) {
    return this.httpClient.post<any>('api/OrderRegister/CancelOrders', ids);
  }

  public generateValuation(id: number) {
    return this.httpClient.post<any>('api/OrderRegister/GenerateValuation', id);
  }

  public removeOrders(ids: number[]) {
    return this.httpClient.post<any>('api/OrderRegister/RemoveOrders', ids);
  }

  public GetWdtNotification(contractorNumber: string): Observable<WDTconfirm> {
    return this.httpClient.get<WDTconfirm>('api/Wdt/GetWdtNotification?contractorNumber=' + contractorNumber);
  }
  public getProformsWithOrders(
    contractorNumber: string,
    filterColumn: string,
    filterrTerm: string,
    sortColumn: string,
    sortDirection: string,
    pageIndex: number,
    pageSize: number,
    dateFrom: string,
    dateTo: string,
    isMineOrders: boolean
  ): Observable<ProformsListData> {
    if (filterrTerm === '') {
      filterColumn = '';
    }

    return this.httpClient.get<ProformsListData>(
      'api/OrderRegister/GetProformsWithOrders?contractorNumber=' +
        contractorNumber +
        '&searchColumn=' +
        filterColumn +
        '&searchTerm=' +
        filterrTerm +
        '&columnKeySort=' +
        sortColumn +
        '&kindSort=' +
        sortDirection +
        '&pageNumber=' +
        (pageIndex + 1) +
        '&itemPerPage=' +
        pageSize +
        '&dateFrom=' +
        dateFrom +
        '&dateTo=' +
        dateTo +
        '&isMineOrders=' +
        isMineOrders
    );
  }
  public GetComplaintOrders(
    contractorNumber: string,
    filterColumn: string,
    filterrTerm: string,
    sortColumn: string,
    sortDirection: string,
    pageIndex: number,
    pageSize: number,
    dateFrom: string,
    dateTo: string,
    isMineOrders: boolean
  ): Observable<ProformsListData> {
    if (filterrTerm === '') {
      filterColumn = '';
    }

    return this.httpClient.get<ProformsListData>(
      'api/OrderRegister/GetProformsWithOrders?contractorNumber=' +
        contractorNumber +
        '&searchColumn=' +
        filterColumn +
        '&searchTerm=' +
        filterrTerm +
        '&columnKeySort=' +
        sortColumn +
        '&kindSort=' +
        sortDirection +
        '&pageNumber=' +
        (pageIndex + 1) +
        '&itemPerPage=' +
        pageSize +
        '&dateFrom=' +
        dateFrom +
        '&dateTo=' +
        dateTo +
        '&isMineOrders=' +
        isMineOrders
    );
  }
 
  public getCountriesForEstimateOrder(cultureId): Observable<Country[]> {
    var jezyk = 1;
    // console.log(this.tresci.jezyki);
    this.tresci.jezyki.forEach(j => {
     
    });
    return this.httpClient.get<Country[]>('api/Additives/GetCountries?cultureId=' + cultureId);
  }
  public getValuationForEstimateOrder(orderEstimate: OrderEstimate): Observable<Blob> {
    return this.httpClient.post('api/ActiveForm/GetValuationContractorClientPrint?culture=' + window['culture'], orderEstimate, { responseType: 'blob' });
  }

  public getSpecificationForEstimateOrder(orderEstimate: OrderEstimate) {
    return this.httpClient.post('api/ActiveForm/GetSpecificationContractorClientPrint?culture=' + window['culture'], orderEstimate, { responseType: 'blob' });
  }

  public getAttachmentFile(url: string): Observable<any> {
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  public getWdts(sortColumn: string, sortDirection: string, contractorNumber: string, dateFrom: string, dateTo: string, radioCheckedOption: WdtStatusEnum): Observable<any> {
    const data = {
      ContractorNumber: contractorNumber,
    };
    return this.httpClient.post(
      'api/Wdt/GetWdtRegister?' + 'dateFrom=' + dateFrom + '&columnKeySort=' + sortColumn + '&kindSort=' + sortDirection + '&dateTo=' + dateTo + '&wdtStatus=' + radioCheckedOption,
      data
    );
  }

  public getWdtPositions(wdts: Delivery[]): Observable<any> {
    return this.httpClient.post('api/Wdt/GetWdtPosition', wdts);
  }

  public confirmAllWdt(wdts: Delivery[]) {
    return this.httpClient.post('/api/Wdt/ConfirmAllWdt', wdts);
  }

  public confirmWdt(wdt: Delivery) {
    return this.httpClient.post('/api/Wdt/ConfirmWdt', wdt);
  }

  public calculateValuation(id: number) {
    return this.httpClient.post(`/api/OrderRegister/GenerateValuation?id=${id}`, {});
  }
}
